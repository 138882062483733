import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { useDispatch } from "react-redux";
import {
  CreateSlotSeriesRequest,
  fetchSlotSeriesApi,
} from "shared/fetch/src/apis/SlotSeriesApi";
import { showSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotsQueryKey } from "./useGetSlots";
import { SlotSeriesOutput } from "shared/fetch/src/models/SlotSeriesOutput";

type CreateSlotSeriesMutationFn = (
  requestParameters: CreateSlotSeriesRequest
) => Promise<SlotSeriesOutput>;

const useCreateSlotSeries = (
  config?: UseMutationOptions<SlotSeriesOutput, Error, CreateSlotSeriesRequest>
): UseMutationResult<SlotSeriesOutput, Error, CreateSlotSeriesRequest> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const mutationFn: CreateSlotSeriesMutationFn = (
    requestParameters: CreateSlotSeriesRequest
  ) => {
    return fetchSlotSeriesApi.createSlotSeries(requestParameters)
    //return DefaultApi.createSlotSeries(requestParameters) as Promise<SlotSeriesOutput>;
  };

  const defaultConfig: UseMutationOptions<
    SlotSeriesOutput,
    Error,
    CreateSlotSeriesRequest
  > = {
    onSuccess: () => {
      dispatch(showSnackbar("Slot series has been successfully created."));
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    },
    onError: () => {
      dispatch(showSnackbar("Failed to create slot series", "danger"));
    },
    ...config,
  };

  return useMutation<SlotSeriesOutput, Error, CreateSlotSeriesRequest>(
    mutationFn,
    defaultConfig
  );
};

export default useCreateSlotSeries;
