import React, { FunctionComponent } from "react";
import Box from "components/Box";
import Button from "components/Button";
import Dialog from "components/Dialog";
import DialogActions from "components/Dialog/DialogActions";
import DialogContent from "components/Dialog/DialogContent";
import DialogContentText from "components/Dialog/DialogContentText";
import DialogTitle from "components/Dialog/DialogTitle";
import usePatchMemberAccountInfo from "shared/features/memberProfile/usePatchMemberAccountInfo";
import { selectLoggedInUser } from "shared/features/user/selectors";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import { fetchUserFromTokenRequest } from "shared/features/user";
import { showSnackbar } from "shared/state/ui/snackbar";

import { store } from "shared/state/StateManagement";
import { requestAsync } from "redux-query";

import Markup from "components/UniversalConsent/Markup";
import Style from "./Style";

interface ModalProps {
  open: boolean;
  toggle: () => void;
  consentAffirmed: (e?: any) => void;
  noConsent: () => void;
}

const ConsentForCareModal: FunctionComponent<ModalProps> = ({
  open,
  consentAffirmed,
  noConsent,
  toggle,
}) => {
  const title = "Crossover Universal Consent (effective 2024)";
  const loggedInUser = selectLoggedInUser();
  const { accountInfo } = useGetAccountInfo({
    // @ts-ignore: Object is possibly 'null'.
    id: loggedInUser.id?.toString(),
  });
  const { updateUserInfo } = usePatchMemberAccountInfo();
  const consentAffirmedWithUpdate = () => {
    updateUserInfo(
      {
        // @ts-ignore: Object is possibly 'null'.
        id: accountInfo.id,
        body: {
          consented: true,
        },
      },
      {
        onSuccess: () => {
          store.dispatch(
            requestAsync({
              ...fetchUserFromTokenRequest(),
              force: true,
            })
          );
          store.dispatch(showSnackbar("You successfully consented"));
        },
        onError: () => {
          store.dispatch(
            showSnackbar("There was an issue saving your consent", "danger")
          );
        },
        onSettled: consentAffirmed,
      }
    );
  };

  const handleConsent = consentAffirmedWithUpdate;

  return (
    <Dialog
      open={open}
      onClose={toggle}
      ariaLabelledBy="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-e2e="first-eoc-consent"
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title" onClose={toggle}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description" component="span">
          <Style />
          <Markup />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleConsent}
          throttleButtonTime={1000}
          data-e2e="consent-button"
        >
          I Consent
        </Button>
        <Box paddingTop="1.33em" paddingBottom="1.33em">
          <Button
            color="link-primary"
            onClick={noConsent}
            data-e2e="no-consent-button"
          >
            I Do Not Consent
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentForCareModal;
