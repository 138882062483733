import React from "react";
import Dialog from "components/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import classes from "./SlotActionDrawer/index.module.css";
import { ButtonBase, Typography } from "@mui/material";
import Button from "components/Button";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { addMonths, subMonths, format } from "date-fns";
import { PickersCalendarHeaderProps } from "@mui/x-date-pickers/PickersCalendarHeader";
import makeStyles from "@mui/styles/makeStyles";
import { Calendar } from "react-multi-date-picker";
import type { Value } from "react-multi-date-picker";
import OOOorClearConfirmModal from "./OOOorClearConfirmModal";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

interface IProps {
  oooOrClearModalOpen: boolean;
  setOOOorClearModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  provider: string | undefined;
  providerId: string | undefined;
  confirmOOOorClearModalOpen: boolean;
  setConfirmOOOorClearModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isClear: boolean;
}

const useStyles = makeStyles(() => ({
  customCalendarHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "8px",
    alignItems: "center",
  },
}));

const OOOorClearCalendarHeader = (
  props: PickersCalendarHeaderProps<dateFns>
) => {
  const { currentMonth, onMonthChange } = props;
  const OOOCalendarHeaderClasses = useStyles();

  const selectNextMonth = () => {
    /* TODO: figure out type issues */
    /* @ts-ignore */
    onMonthChange(addMonths(currentMonth, 1), "left");
  };
  const selectPreviousMonth = () => {
    /* TODO: figure out type issues */
    /* @ts-ignore */
    onMonthChange(subMonths(currentMonth, 1), "right");
  };

  return (
    <div
      className={OOOCalendarHeaderClasses.customCalendarHeader}
      data-testid="ooo-date-picker-header"
    >
      <Stack spacing={1} direction="row">
        <IconButton onClick={selectPreviousMonth} title="Previous month">
          <ChevronLeft />
        </IconButton>
      </Stack>
      <Typography variant="body2" sx={{ color: "#2B3949", fontWeight: "bold" }}>
        {/* @ts-ignore */}
        {format(currentMonth, "MMMM y")}
      </Typography>
      <Stack spacing={1} direction="row">
        <IconButton onClick={selectNextMonth} title="Next month">
          <ChevronRight />
        </IconButton>
      </Stack>
    </div>
  );
};

const OOOorClearModal = ({
  oooOrClearModalOpen,
  setOOOorClearModalOpen,
  confirmOOOorClearModalOpen,
  setConfirmOOOorClearModalOpen,
  provider,
  providerId,
  isClear,
}: IProps) => {
  const [values, setValues] = React.useState<Value[] | []>([]);

  // @ts-ignore
  const selectedDates = values.map((val) => new Date(val));

  const handleConfirmOOO = () => {
    setOOOorClearModalOpen(false);
    setConfirmOOOorClearModalOpen(true);
  };

  return (
    <>
      <OOOorClearConfirmModal
        selectedDates={selectedDates}
        provider={provider}
        setOOOorClearModalOpen={setOOOorClearModalOpen}
        confirmOOOorClearModalOpen={confirmOOOorClearModalOpen}
        setConfirmOOOorClearModalOpen={setConfirmOOOorClearModalOpen}
        providerId={providerId}
        isClear={isClear}
      />

      <Dialog
        open={oooOrClearModalOpen}
        ariaLabelledBy={"dialog-title"}
        onClose={() => setOOOorClearModalOpen(false)}
      >
        {" "}
        <div className={classes.oooMemberModal} data-testid="ooo-modal">
          <div className={classes.oooModalTitleContainer}>
            <ButtonBase
              className={classes.oooModalCancel}
              role="button"
              aria-label={"Close ooo or clear modal"}
              onClick={() => {
                setOOOorClearModalOpen(false);
                setValues([]);
              }}
            >
              <CloseIcon className={classes.closeIcon} />
            </ButtonBase>
            <DialogTitle className={classes.cancelModalTitle} id="dialog-title">
              {" "}
              {isClear ? "Clear Provider's Schedule" : "Set Out of Office"}
            </DialogTitle>{" "}
          </div>
          <DialogContent>
            <DialogContentText variant={"body2"}>
              <Typography sx={{ paddingBottom: "16px" }}>
                Choose the day(s) {isClear ? " to clear" : " OOO"} for{" "}
                <strong style={{ color: "#2B3949" }}>{provider}</strong>
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Calendar
                  plugins={[
                    <DatePanel
                      key={1}
                      sort="date"
                      header={"Selected Date(s)"}
                      eachDaysInRange
                    />,
                  ]}
                  formatMonth={(month, year) => {
                    return month + " " + year;
                  }}
                  formatYear={() => {
                    return "";
                  }}
                  monthYearSeparator=" "
                  minDate={new Date()}
                  // @ts-ignore
                  style={{
                    backgroundColor: "#F3F6F8",
                    border: "none",
                  }}
                  shadow={false}
                  range
                  weekPicker={false}
                  value={values}
                  onChange={setValues}
                  format={"MMM D, YYYY"}
                  slotProps={{
                    toolbar: {
                      hidden: true,
                    },
                    actionBar: {
                      actions: [],
                    },
                  }}
                  slots={{ calendarHeader: OOOorClearCalendarHeader }}
                />
              </div>
            </DialogContentText>
            <DialogActions className={classes.oooModalActions}>
              <Button
                disabled={values.length === 0}
                color={"primary"}
                onClick={handleConfirmOOO}
                aria-label={"Confirm OOO or clear days"}
                className={classes.cancelMemberButton}
              >
                Confirm
              </Button>
              <Button
                color={"link-primary"}
                onClick={() => {
                  setOOOorClearModalOpen(false);
                  setValues([]);
                }}
                aria-label={"Cancel, do not set OOO or clear schedule"}
              >
                {" "}
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default OOOorClearModal;
