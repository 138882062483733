import CloseIcon from "@mui/icons-material/Close";
import { ButtonBase } from "@mui/material";
import Dialog from "components/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "components/Button";
import React, { useEffect, useRef, useState } from "react";
import useDeleteSlot from "shared/features/xocal/useDeleteSlot";
import useGetSeriesByID from "shared/features/xocal/useGetSeriesByID";
import useDeleteSlotSeries from "shared/features/xocal/useDeleteSlotSeries";
import {
  DeleteSlotSeriesDryRunEnum,
  UpdateSlotSeriesDryRunEnum,
} from "shared/fetch/src/apis";
import { DeleteSlotSeriesConflictResolutionEnum } from "shared/fetch/src/models/DeleteSlotSeries";
import CalendarWarningIcon from "./CalendarWarningIcon";
import { SlotSeriesActionOptions } from "./SlotSeriesActionConfirmModal";
import classes from "./index.module.css";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import { showSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotsQueryKey } from "shared/features/xocal/useGetSlots";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";
import uniqBy from "lodash/uniqBy";
import { List, ListItem } from "@mui/material";
import { ValuesType } from "./utils";
import { XOCalProvider } from "shared/fetch/src/models/XOCalProvider";
import { format, subDays } from "date-fns";
import useUpdateSlotSeries from "shared/features/xocal/useUpdateSlotSeries";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { UpdateSlotSeriesConflictResolutionEnum } from "shared/fetch/src/models/UpdateSlotSeries";

interface CancelConfirmModalProps {
  deleteModalOpen: boolean;
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  slotId: string;
  slotSeriesId?: string;
  slotSeriesOptions?: SlotSeriesActionOptions;
  values: ValuesType;
  provider: XOCalProvider | undefined;
  slot: SlotOutput | undefined;
}

const CancelConfirmModal: React.FC<CancelConfirmModalProps> = ({
  deleteModalOpen,
  setDeleteModalOpen,
  handleClose,
  slotId,
  slotSeriesId,
  slotSeriesOptions,
  values,
  provider,
  slot,
}) => {
  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const [deleteCount, setDeleteCount] = useState<number>(0);
  const [conflictingAppointments, setConflictingAppointments] = useState<
    AppointmentOutput[]
  >([]);
  const [isFullyBookedSeries, setIsFullyBookedSeries] =
    useState<boolean>(false);
  const [isFirstBookedSlot, setIsFirstBookedSlot] = useState<boolean>(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteSlot } = useDeleteSlot({
    onSuccess: () => {
      dispatch(
        showSnackbar(
          // Success! The slot for Casper Miller, LAC on Oct 23, 2023 @ 8:00am has been deleted.
          `Success! The slot for ${provider?.name} on ${
            values.startAt && format(new Date(values.startAt), "MMM d, yyyy")
          } @ ${values.startTime} has been deleted.`
        )
      );
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    },
  });
  const { mutateAsync: deleteSlotSeries, isLoading } = useDeleteSlotSeries({
    onSuccess: handleDeleteSlotSeriesSuccess,
  });

  const { mutateAsync: updateSlotSeries } = useUpdateSlotSeries({
    onSuccess: handleUpdateSlotSeriesSuccess,
  });

  const slotSeries = useGetSeriesByID({ id: slotSeriesId });

  useEffect(() => {
    // setIsFullyBookedSeries if all of the slots are booked
    const slotsInSeries = slotSeries.data?.slots;
    let isFullyBooked = slotsInSeries?.every((slot) => {
      return slot?.appointments?.length !== 0;
    });
    // if we don't get a value we can assume it is false
    if (isFullyBooked === undefined) {
      isFullyBooked = false;
    }
    setIsFullyBookedSeries(isFullyBooked);
    // find out if the first booked and not canceled slot of the series is selected
    if (slotsInSeries?.[0].id === slot?.id) {
      setIsFirstBookedSlot(true);
    }
  }, [slotSeries]);

  useEffect(() => {
    if (slotSeriesOptions === SlotSeriesActionOptions.ALL_SLOTS) {
      performDryRunDeleteSlotSeries();
    } else if (slotSeriesOptions === SlotSeriesActionOptions.THIS_AND_FUTURE) {
      performDryRunUpdateSlotSeries();
    } else if (slotSeriesOptions === SlotSeriesActionOptions.THIS_SLOT) {
      if (slot?.appointments && slot.appointments.length > 0) {
        setConflictingAppointments(slot.appointments);
      }
    }
  }, [slotSeriesOptions, slot]);

  function handleDeleteSlotSeriesSuccess(data: any) {
    if (data.summary) {
      setDeleteCount(data.summary.totalRemovedSlots || 0);
      if (data.summary.conflictingAppointments) {
        setConflictingAppointments(data.summary.conflictingAppointments);
      } else {
        setConflictingAppointments([]);
      }
    } else {
      dispatch(
        showSnackbar(
          `${deleteCount} slots for ${provider?.name} @ ${values.startTime} have been deleted`
        )
      );
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    }
  }

  function handleUpdateSlotSeriesSuccess(data: any) {
    if (data.summary) {
      setDeleteCount(data.summary.totalRemovedSlots || 0);
      if (data.summary.conflictingAppointments) {
        setConflictingAppointments(data.summary.conflictingAppointments);
      } else {
        setConflictingAppointments([]);
      }
    } else {
      dispatch(
        showSnackbar(
          `${deleteCount} slots for ${provider?.name} @ ${values.startTime} have been deleted`
        )
      );
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    }
  }

  const updateSlotData = {
    ...slot,
    daysActive: values.daysActive,
    startTime: values.startTime!,
    endTime: values.endTime!,
    id: slotSeriesId!,
    startAt: values.startAt,
    endAt: values.endAt,
    seriesStart: values.seriesStart
      ? format(new Date(values.seriesStart), "yyyy-MM-dd")
      : undefined,
    seriesEnd: slot?.startAt
      ? format(subDays(new Date(slot?.startAt), 1), "yyyy-MM-dd")
      : undefined,
    restrictedTo: values.restrictedTo,
    conflictResolution: UpdateSlotSeriesConflictResolutionEnum.Cancel,
  };

  async function performDryRunDeleteSlotSeries() {
    if (slotSeriesId) {
      await deleteSlotSeries({
        id: slotSeriesId!,
        dryRun: DeleteSlotSeriesDryRunEnum.True,
        deleteSlotSeries: {},
      });
    }
  }

  async function performDryRunUpdateSlotSeries() {
    if (slotSeriesId) {
      await updateSlotSeries({
        id: slotSeriesId,
        dryRun: UpdateSlotSeriesDryRunEnum.True,
        updateSlotSeries: updateSlotData,
      });
    }
  }

  const handleDelete = async () => {
    if (slotSeriesOptions === SlotSeriesActionOptions.THIS_SLOT) {
      slotId && (await deleteSlot({ id: slotId }));
    } else if (
      slotSeriesOptions === SlotSeriesActionOptions.ALL_SLOTS &&
      slotSeriesId
    ) {
      await deleteSlotSeries({
        id: slotSeriesId,
        dryRun: DeleteSlotSeriesDryRunEnum.False,
        deleteSlotSeries: {
          conflictResolution: DeleteSlotSeriesConflictResolutionEnum.Cancel,
        },
      });
    } else if (
      slotSeriesOptions === SlotSeriesActionOptions.THIS_AND_FUTURE &&
      slotSeriesId
    ) {
      // if the series is all booked (NO unbooked slots),
      // AND the first booked and not canceled slot of the series is selected
      // then call deleteSlotSeries rather than updateSlotSeries

      if (isFullyBookedSeries && isFirstBookedSlot) {
        await deleteSlotSeries({
          id: slotSeriesId,
          dryRun: DeleteSlotSeriesDryRunEnum.False,
          deleteSlotSeries: {
            conflictResolution: DeleteSlotSeriesConflictResolutionEnum.Cancel,
          },
        });
      } else {
        await updateSlotSeries({
          id: slotSeriesId,
          dryRun: UpdateSlotSeriesDryRunEnum.False,
          updateSlotSeries: updateSlotData,
        });
      }
    }
    setDeleteModalOpen(false);
    handleClose();
  };

  const renderConflictDialog = () => {
    const uniquePatients = uniqBy(
      conflictingAppointments
        .map((appointment) => appointment.patientInfo)
        .filter(
          (patientInfo): patientInfo is NonNullable<typeof patientInfo> =>
            patientInfo != null
        ),
      "id"
    );

    return (
      <>
        <div
          className={classes.deleteBookedSlotConfirmationModal}
          data-testid="booked-cancel-confirm-modal"
        >
          <div
            className={classes.deleteBookedSlotConfirmationModalTitleContainer}
          >
            <DialogTitle
              className={classes.deleteBookedSlotConfirmationModalTitle}
              id="cancel-confirm-modal-title"
            >
              {" "}
              This slot has {conflictingAppointments?.length} visit
              {conflictingAppointments &&
                conflictingAppointments?.length > 1 &&
                "s"}{" "}
              scheduled
            </DialogTitle>
            <ButtonBase
              className={classes.deleteBookedSlotConfirmationModalCancel}
              role="button"
              aria-label={"Close delete slot modal"}
              onClick={() => setDeleteModalOpen(false)}
            >
              <CloseIcon className={classes.closeIcon} />
            </ButtonBase>
          </div>

          <DialogContent>
            <DialogContentText variant={"body2"} sx={{ width: "90%" }}>
              Deleting slots will send scheduled visits to the conflicts queue.
            </DialogContentText>
            <DialogContentText variant={"body2"}>
              <List className={classes.deleteBookedSlotConfirmationModalList}>
                {uniquePatients &&
                  uniquePatients.map((patient, key) => (
                    <ListItem
                      alignItems="flex-start"
                      key={key}
                      sx={{
                        display: "list-item",
                        fontWeight: "bold",
                        paddingLeft: "5px",
                        fontSize: "16px",
                      }}
                    >
                      {patient.name}
                    </ListItem>
                  ))}
              </List>
            </DialogContentText>
            <DialogActions
              className={classes.deleteBookedSlotConfirmationModalActions}
            >
              <Button
                color={"primary"}
                onClick={handleDelete}
                aria-label={
                  "Send booked visit(s) to conflict cue and delete slot"
                }
              >
                Delete
              </Button>
              <Button
                color={"secondary"}
                onClick={() => setDeleteModalOpen(false)}
                aria-label={"Go back, do not delete slot"}
              >
                {" "}
                Go back
              </Button>
            </DialogActions>
          </DialogContent>
        </div>
      </>
    );
  };

  const renderConfirmDialog = () => (
    <div className={classes.deleteModal} data-testid="cancel-confirm-modal">
      <div className={classes.deleteModalTitleContainer}>
        <CalendarWarningIcon />
        <ButtonBase
          className={classes.deleteModalCancel}
          onClick={() => setDeleteModalOpen(false)}
          aria-label="Close delete slot modal"
        >
          <CloseIcon className={classes.closeIcon} />
        </ButtonBase>
      </div>
      <DialogTitle
        className={classes.deleteModalTitle}
        id="cancel-confirm-modal-title"
      >
        Are you sure?
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body1" sx={{ color: "black" }}>
          {slotSeriesOptions !== SlotSeriesActionOptions.THIS_SLOT
            ? `You are about to delete ${deleteCount} slots in the series. Once you delete, they are gone for good.`
            : "Once you delete, it's gone for good."}
        </DialogContentText>
        <DialogActions className={classes.deleteModalActions}>
          <Button
            ref={deleteButtonRef}
            color="primary"
            onClick={handleDelete}
            aria-label="Yes, delete. Once you delete it is gone for good."
          >
            Yes, delete
          </Button>
          <Button
            color="link-primary"
            onClick={() => setDeleteModalOpen(false)}
            aria-label="Cancel, do not delete slot"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </div>
  );

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Dialog
      open={deleteModalOpen}
      maxWidth="xs"
      ariaLabelledBy="cancel-confirm-modal-title"
      onClose={() => setDeleteModalOpen(false)}
    >
      {conflictingAppointments.length > 0
        ? renderConflictDialog()
        : renderConfirmDialog()}
    </Dialog>
  );
};

export default CancelConfirmModal;
