import { addDays, format, isPast } from "date-fns";
import useXoCalFormState, { ValidationSchema } from "hooks/useXoCalFormState";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import useCreateSlot from "shared/features/xocal/useCreateSlot";
import useCreateSlotSeries from "shared/features/xocal/useCreateSlotSeries";
import { getFetchSlotsQueryKey } from "shared/features/xocal/useGetSlots";
import useReplicateSingleSlotIntoSeries from "shared/features/xocal/useReplicateSingleSlotIntoSeries";
import useScheduleAppointment from "shared/features/xocal/useScheduleAppointment";
import useUpdateSlot from "shared/features/xocal/useUpdateSlot";
import useUpdateSlotSeries from "shared/features/xocal/useUpdateSlotSeries";
import { UpdateSlotSeriesDryRunEnum } from "shared/fetch/src/apis/SlotSeriesApi";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";
import { RestrictedToEnum } from "shared/fetch/src/models/RestrictedToEnum";
import { SlotSeriesOutput } from "shared/fetch/src/models/SlotSeriesOutput";
import { SlotSeriesOutputUpdateDryRun } from "shared/fetch/src/models/SlotSeriesOutputUpdateDryRun";
import { SlotVisibilityEnum } from "shared/fetch/src/models/SlotVisibilityEnum";
import { UpdateSlotSeriesConflictResolutionEnum } from "shared/fetch/src/models/UpdateSlotSeries";
import { showSnackbar } from "shared/state/ui/snackbar";
import { SlotSeriesActionOptions } from "./SlotSeriesActionConfirmModal";
import {
  ValuesType,
  formatTime,
  getDateFromParams,
  hasDatePassed,
  isParamsDateInPast,
} from "./utils";
import { XOCalProvider } from "shared/fetch/src/models/XOCalProvider";
import { useGetProviders } from "shared/features/xocal/useGetProviders";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import { JsonUser } from "shared/fetch/src/models/JsonUser";

const useSlotActionDrawer = (
  isSlotCreation: boolean,
  onClose: () => void,
  provider: XOCalProvider | undefined,
  clinicId: string | undefined, // should we allow undefined here?
  slotId: string | undefined,
  slot?: SlotOutput,
  timezone?: string
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const user = useSelector(getUser) as JsonUser;
  const hasFullXoCalPermissions = Boolean(user.hasFullXoCalPermissions);

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [
    deleteBookedSlotConfirmationModalOpen,
    setDeleteBookedSlotConfirmationModalOpen,
  ] = useState(false);
  const [deleteSlotSeriesModalOpen, setDeleteSlotSeriesModalOpen] =
    useState(false);
  const [slotSeriesOptions, setSlotSeriesOptions] =
    useState<SlotSeriesActionOptions>(SlotSeriesActionOptions.THIS_SLOT);
  const [updateSlotSeriesModalOpen, setUpdateSlotSeriesModalOpen] =
    useState(false);
  const [isEditScheduledSlot, setIsEditScheduledSlot] = useState(false);
  const [isKeepOrCancelFlow, setIsKeepOrCancelFlow] = useState(false);
  const [isChangingVisitType, setIsChangingVisitType] = useState(false);
  const [isCreatingSeriesOutOfBookedSlot, setIsCreatingSeriesOutOfBookedSlot] =
    useState(false);
  const [updateSlotSeriesOptions, setUpdateSlotSeriesOptions] =
    useState<SlotSeriesActionOptions>(SlotSeriesActionOptions.THIS_SLOT);

  const [conflictingAppointments, setConflictingAppointments] = useState<
    AppointmentOutput[]
  >([]);
  const [slotDrawerProvider, setSlotDrawerProvider] = useState<
    XOCalProvider | undefined
  >(provider);
  const clinicProviders = useGetProviders(clinicId!);

  const selectedMember = searchParams.get("member");

  const SLATAError =
    "Something went wrong: Factories::Episodes::FromAppointment::EligibilityError";

  const { mutateAsync: createSlot, isLoading: isCreateSlotLoading } =
    useCreateSlot({
      onSuccess: () => {
        dispatch(showSnackbar("Slot has been successfully created."));
        queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
      },
    });

  const {
    mutateAsync: createSlotSeries,
    isLoading: isCreateSlotSeriesLoading,
  } = useCreateSlotSeries();
  const {
    mutateAsync: replicateSingleSlotIntoSeries,
    isLoading: isReplicateSingleSlotIntoSeriesLoading,
  } = useReplicateSingleSlotIntoSeries();
  const { mutateAsync: updateSlot, isLoading: isUpdateSlotLoading } =
    useUpdateSlot();
  const {
    mutateAsync: updateSlotSeries,
    isLoading: isUpdateSlotSeriesLoading,
  } = useUpdateSlotSeries();
  const {
    isLoading: isScheduleAppointmentLoading,
    mutateAsync: scheduleAppointment,
  } = useScheduleAppointment();

  const validateSchema: ValidationSchema<ValuesType> = (
    _values: ValuesType
  ) => {
    const _errors: { [key: string]: string } = {};

    if (
      !_values.selectedProviderId &&
      !searchParams.get("slotActionDrawerProvider")
    ) {
      _errors.selectedProviderId = "Provider ID is required.";
    }

    if (!_values.startAt) {
      _errors.startAt = "Start must be selected.";
    }

    if (!_values.endAt) {
      _errors.endAt = "End time must be selected.";
    }

    // appointmentType is required when creating slot from the drawer
    // but slot creating by dragging have no appointment type, and SLOT appt type
    // is not required when updating, even if the slot has an appointment on it
    if (
      isSlotCreation &&
      (!_values.appointmentTypes || _values.appointmentTypes.length === 0)
    ) {
      _errors.appointmentTypes =
        "At least one appointment type must be selected.";
    }
    // TODO: validate seriesStart and seriesEnd

    return _errors;
  };

  const initialValues = {
    selectedProviderId:
      provider?.providerId ||
      (searchParams.get("slotActionDrawerProvider") as string),
    duration: null,
    selectedDate: null,
    maxOverbook: 0,
    maxPatients: 1,
    restrictedTo: RestrictedToEnum.VirtualVisitReferral,
    reason: null,
    repeats: false,
    daysActive: [],
    seriesStart: null,
    seriesEnd: null,
    startTime: null,
    endTime: null,
    visibility: SlotVisibilityEnum.Hold,
    appointmentTypes: [],
  };

  const { values, handleChange, resetFields, setField, errors, validate } =
    useXoCalFormState<ValuesType>({
      initialValues,
      mode: "onChange",
      enableReinitialize: true,
      validateSchema,
    });

  const isRequestFromSlotSettingsTab = () => {
    return activeTab === 0;
  };

  const updateData = {
    clinicId: clinicId as string,
    providerId: (values?.selectedProviderId ||
      searchParams.get("slotActionDrawerProvider")) as string,
    appointmentTypes: isRequestFromSlotSettingsTab()
      ? values?.appointmentTypes
      : values?.appointmentRequestAppointmentTypes,
    visibility: values?.visibility,
    maxPatients: values?.maxPatients,
    maxOverbook: values?.maxOverbook,
    restrictedTo: values?.restrictedTo,
    startAt: values?.startAt!,
    endAt: values?.endAt!,
    appointments: [],
  };

  useEffect(() => {
    // If the user is updating an existing slot the provider will be defined on the slot object.
    // But if a slot is being created we to need find the provider's global id in the params.
    let providerGlobalId: string | null | undefined;
    if (isSlotCreation) {
      // in any case that slotActionDrawerProvider is not defined, the providerId in the params will be correct
      providerGlobalId =
        searchParams.get("slotActionDrawerProvider") ||
        searchParams.get("providerId");
    } else {
      providerGlobalId = slot?.providerId;
    }

    const slotDrawerProvider = clinicProviders.find(
      (p) => p.providerId === providerGlobalId
    );
    setField("selectedProviderId", providerGlobalId);
    // set provider object in the SlotActionDrawer's state
    setSlotDrawerProvider(slotDrawerProvider!);
  }, [isSlotCreation, slot]);

  useEffect(() => {
    if (!isSlotCreation && slot) {
      resetFields({
        ...slot,
        selectedProviderId:
          searchParams.get("slotActionDrawerProvider")! ||
          slot?.providerId ||
          provider?.providerId!,
        duration: values.duration,
        selectedDate: values.selectedDate,
        reason: values.reason,
        repeats: slot.slotSeries?.startAt ? true : false,
        maxOverbook: slot.maxOverbook,
        maxPatients: slot.maxPatients,
        visibility: slot.visibility!,
        seriesStart:
          values.seriesStart ||
          (slot.slotSeries?.startAt?.toISOString() as string),
        seriesEnd:
          values.seriesEnd || (slot.slotSeries?.endAt?.toISOString() as string),
        daysActive: slot.slotSeries?.daysActive || [],
        startTime: slot.startAt
          ? formatTime(new Date(slot.startAt), timezone!)
          : null,
        endTime: slot.endAt
          ? formatTime(new Date(slot.endAt), timezone!)
          : null,
      });
    }
  }, [slot, isSlotCreation]);

  const handleClose = () => {
    resetFields(initialValues);
    searchParams.delete("appointmentId");
    searchParams.delete("member");
    setSearchParams(searchParams);
    onClose();
  };

  const runUpdateSlotSeries = async <T extends boolean>({
    dryRun,
    conflictResolution,
  }: {
    option?: SlotSeriesActionOptions;
    dryRun?: T;
    conflictResolution?: UpdateSlotSeriesConflictResolutionEnum;
  }): Promise<
    T extends true
      ?
          | { data: SlotSeriesOutputUpdateDryRun; success: true }
          | { success: false; error: any }
      :
          | { data: SlotSeriesOutput; success: true }
          | { success: false; error: any }
  > => {
    if (!slot?.slotSeries?.id) {
      throw new Error("No slot series found to update.");
    }

    const getSeriesStart = () => {
      if (slotSeriesOptions === SlotSeriesActionOptions.THIS_AND_FUTURE) {
        return slot.startAt
          ? format(new Date(slot.startAt), "yyyy-MM-dd")
          : undefined;
      } else {
        return values.seriesStart
          ? format(new Date(values.seriesStart), "yyyy-MM-dd")
          : undefined;
      }
    };

    const seriesUpdateData = {
      ...updateData,
      daysActive: values.daysActive,
      seriesStart: getSeriesStart(),
      seriesEnd: values.seriesEnd
        ? format(new Date(values.seriesEnd), "yyyy-MM-dd")
        : undefined,
      startTime: values.startTime!,
      endTime: values.endTime!,
      conflictResolution,
    };

    try {
      const result = await updateSlotSeries({
        id: slot.slotSeries.id.toString(),
        dryRun: dryRun
          ? UpdateSlotSeriesDryRunEnum.True
          : UpdateSlotSeriesDryRunEnum.False,
        updateSlotSeries: seriesUpdateData,
      });

      if (dryRun) {
        return {
          data: result as SlotSeriesOutputUpdateDryRun,
          success: true,
        } as any;
      } else {
        return { data: result as SlotSeriesOutput, success: true } as any;
      }
    } catch (error) {
      return { success: false, error, data: null } as any;
    }
  };

  const handleClickDeleteButton = () => {
    const hasAppointments = slot?.appointments?.length! > 0;
    const isSlotSeries = Boolean(slot?.slotSeries);

    if (hasAppointments && !isSlotSeries) {
      setDeleteBookedSlotConfirmationModalOpen(true);
    } else if (isSlotSeries) {
      setDeleteSlotSeriesModalOpen(true);
    } else {
      setDeleteModalOpen(true);
    }
  };

  const handleCreateSlot = async () => {
    try {
      await createSlot({
        createSlotCore: {
          clinicId: clinicId as string,
          providerId:
            searchParams.get("slotActionDrawerProvider")! ||
            values.selectedProviderId!,
          appointmentTypes: values?.appointmentTypes,
          visibility: values?.visibility,
          maxPatients: values?.maxPatients,
          maxOverbook: values?.maxOverbook,
          restrictedTo: values?.restrictedTo,
          startAt: values?.startAt!,
          endAt: values?.endAt!,
          appointments: [],
        },
      });
      handleClose();
    } catch (error) {
      dispatch(showSnackbar("Failed to create slot.", "danger"));
    }
  };

  // TODO: remove this and ensure values.seriesStart is ALWAYS correct
  const getSeriesStartDate = () => {
    // values.seriesStart comes from the SlotActionDrawer's form, and can sometimes be undefined
    // or in the past, in that case we ignore it
    return (
      (values.seriesStart &&
        !isPast(new Date(values?.seriesStart!)) &&
        new Date(values.seriesStart)) ||
      // not sure where this value comes from, honestly
      (slot?.slotSeries?.startAt && new Date(slot?.slotSeries?.startAt)) ||
      // this value comes from the datepicker in the SlotActionDrawer
      values.selectedDate ||
      // if no values have been modified in the SlotActionDrawer, we use the date from the params
      // which is set by the datepicker in the calendar sidebar
      getDateFromParams(searchParams)!
    );
  };

  // TODO: remove this and ensure values.seriesEnd is ALWAYS correct
  const getSeriesEndDate = () => {
    return (
      (values.seriesEnd && new Date(values.seriesEnd)) ||
      (slot?.slotSeries?.endAt && new Date(slot?.slotSeries?.endAt)) ||
      addDays(new Date(), 2)
    );
  };

  const handleCreateSlotSeries = async () => {
    // we should install a validation lib or build one ourselves (.)
    if (values.startTime === null || values.endTime === null) {
      return;
    }

    const createSlotSeriesRequestBody = {
      clinicId: clinicId as string,
      providerId:
        values?.selectedProviderId ||
        searchParams.get("slotActionDrawerProvider")!,
      appointmentTypes: values.appointmentTypes || [],
      daysActive: values?.daysActive,
      visibility: values.visibility,
      maxPatients: values?.maxPatients,
      maxOverbook: values?.maxOverbook,
      restrictedTo: values?.restrictedTo,
      seriesStart: getSeriesStartDate(),
      seriesEnd: getSeriesEndDate(),
      startTime: values.startTime,
      endTime: values.endTime,
    };

    try {
      await createSlotSeries({ createSlotSeriesRequestBody });
      handleClose();
    } catch (error) {
      dispatch(showSnackbar("Failed to create slot series.", "danger"));
    }
  };

  const handleCreateButtonClick = () => {
    if (values.repeats) {
      handleCreateSlotSeries();
    } else {
      handleCreateSlot();
    }
  };

  const handleUpdateClick = () => {
    if (!!slot?.appointments?.length && isKeepOrCancelFlow) {
      setIsEditScheduledSlot(true);
    }
    // only open the slot series modal for settings changes, not when scheduling visit
    else if (slot?.slotSeries && isRequestFromSlotSettingsTab()) {
      setUpdateSlotSeriesModalOpen(true);
      // we only perform this update if we are on the slot settings tab, NOT the schedule visit tab
    } else if (isRequestFromSlotSettingsTab()) {
      performUpdate();
    }
  };

  const performUpdate = async ({
    conflictResolution,
  }: {
    conflictResolution?: UpdateSlotSeriesConflictResolutionEnum;
  } = {}) => {
    // staff users cannot modify slots, so return early in the case of a staff user
    // also return early if there is no slot id for some reason
    if (!hasFullXoCalPermissions || !slotId) {
      return;
    }

    const isUserTransformingSlotIntoSeries =
      !slot?.slotSeries && values.repeats;
    const isUpdatingExistingSlotSeries = slot?.slotSeries;

    try {
      // TODO: this section could use comments
      if (isUserTransformingSlotIntoSeries) {
        await replicateSingleSlotIntoSeries({
          id: slotId,
          createSlotSeries: {
            ...updateData,
            startAt: new Date(values.startAt!),
            endAt: new Date(values.endAt!),
            daysActive: values.daysActive,
            seriesStart: getSeriesStartDate(),
            seriesEnd: getSeriesEndDate(),
            startTime: values.startTime!,
            endTime: values.endTime!,
            // if the visit type is a hold, then restrictedTo must be "not_restricted"
            restrictedTo:
              values.visibility === SlotVisibilityEnum.Hold
                ? RestrictedToEnum.NotRestricted
                : values?.restrictedTo,
          },
        });
      } else if (isUpdatingExistingSlotSeries) {
        await runUpdateSlotSeries({
          dryRun: false,
          conflictResolution,
        });
      } else {
        await updateSlot({
          id: slotId,
          createSlotCore: updateData,
        });
      }

      onClose();
      dispatch(showSnackbar("Slot updated successfully.", "success"));
    } catch (error) {
      dispatch(showSnackbar("Failed to update slot.", "danger"));
    }
  };

  const handleScheduleMemberClick = async () => {
    if (!slotId) {
      return;
    }

    // the appointmentType comes from useSelectVisitType to ensure that we have the correct
    // and most up to date appointmentType for this appointment
    try {
      await handleUpdateClick();
      await scheduleAppointment({
        id: slotId,
        scheduleAppointmentCore: {
          patientId: searchParams.get("member")!,
          appointmentType:
            values?.newSlotAppointmentType || values?.appointmentType!,
          reason: values?.reason!,
          selfSchedule: true,
          episodeId: values?.episodeId,
        },
      });
      onClose();
    } catch (error) {
      // get the error message
      const errorMessage = await error.json();
      // different error message if SLATA error or not
      if (errorMessage.error === SLATAError) {
        dispatch(
          showSnackbar(
            "Sorry, this visit is not supported. In order to schedule this visit, please create a new conversation.",
            "danger"
          )
        );
      } else {
        dispatch(showSnackbar("Failed to schedule visit.", "danger"));
      }
    }
  };

  const isSlotDatePast = hasDatePassed(slot?.startAt!);
  // TODO: we shouldn't mix the concerns about dates with concerns about permissions
  const isReadOnly =
    (isSlotCreation ? isParamsDateInPast(searchParams) : isSlotDatePast) ||
    !hasFullXoCalPermissions;

  return {
    activeTab,
    setActiveTab,
    deleteModalOpen,
    setDeleteModalOpen,
    deleteBookedSlotConfirmationModalOpen,
    setDeleteBookedSlotConfirmationModalOpen,
    deleteSlotSeriesModalOpen,
    setDeleteSlotSeriesModalOpen,
    slotSeriesOptions,
    setSlotSeriesOptions,
    updateSlotSeriesModalOpen,
    setIsEditScheduledSlot,
    isEditScheduledSlot,
    setUpdateSlotSeriesModalOpen,
    updateSlotSeriesOptions,
    setUpdateSlotSeriesOptions,
    isCreateSlotLoading,
    isCreateSlotSeriesLoading,
    isReplicateSingleSlotIntoSeriesLoading,
    isUpdateSlotLoading,
    isUpdateSlotSeriesLoading,
    isScheduleAppointmentLoading,
    values,
    handleChange,
    resetFields,
    setField,
    errors,
    validate,
    handleClose,
    handleClickDeleteButton,
    handleCreateButtonClick,
    handleUpdateClick,
    handleScheduleMemberClick,
    handleCreateSlotSeries,
    hasFullXoCalPermissions,
    performUpdate,
    conflictingAppointments,
    setConflictingAppointments,
    runUpdateSlotSeries,
    selectedMember,
    slotDrawerProvider,
    updateSlot,
    updateData, // TODO: name is vague, try to improve
    isReadOnly,
    isSlotDatePast,
    isKeepOrCancelFlow,
    setIsKeepOrCancelFlow,
    handleCreateSlot,
    isChangingVisitType,
    setIsChangingVisitType,
    isCreatingSeriesOutOfBookedSlot,
    setIsCreatingSeriesOutOfBookedSlot,
  };
};

export default useSlotActionDrawer;
