import React, { FunctionComponent, useEffect, useRef } from "react";
import { useTheme, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import Card from "components/Card";
import CardItem from "components/Card/CardItem";
import CardSection from "components/Card/CardSection";
import Box from "components/Box";
import Typography from "components/Typography";
import EocBadge from "components/EocBadge";
import StatusBadge from "components/ConversationCard/StatusBadge";
import InformationLine from "components/ConversationCard//InformationLine";
import { ConversationSummary } from "shared/fetch/src/models/ConversationSummary";
import { getInCompleteActionableItems } from "../EOCTimeline/utils";
import { getToDo } from "./utils";
import { getMessageSubject } from "components/EOCTimeline/TimelineCardSummary/getMessageSubject";
import { Link } from "../Button";
import useFormatDate from "shared/utils/useFormatDate";
import Asset from "../Asset";
import { formatMessageSubject } from "features/selfSchedule/pages/Utils";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import { useNavigate } from "react-router";
import {
  setComponentIdentifier,
  useScrollRestoration,
} from "shared/state/ui/scrollRestoration";
import { ConversationSummaryCoreLastUpdatedUserTypeEnum } from "shared/fetch/src/models/ConversationSummaryCore";
import { CtmConversationSummary } from "shared/fetch/src/models/CtmConversationSummary";
import { RoleOutputCoreSchema } from "shared/fetch/src/models/RoleOutputCoreSchema";
import VucBadge from "components/VucBadge";

interface IProps {
  isCTM?: boolean;
  isArchived?: boolean;
  conversation: ConversationSummary;
  idx?: string;
  isRetchingConversation?: boolean;
}

const useStyles = makeStyles(
  ({ spacing, typography, breakpoints, palette }) => ({
    root: (props: any) => ({
      marginBottom: spacing(2),
      backgroundColor: props.isArchived ? "#EEEFF0" : palette.white,
      padding: spacing(2),
      boxShadow: "none",
      "&:hover": {
        boxShadow: "2px 6px 10px rgba(135, 160, 182, 0.4)",
      },
      transition: "all ease-in-out 0.3s",
      [breakpoints.down("md")]: {
        padding: spacing(3 / 2),
      },
    }),
    eocBadge: {
      padding: "2px",
      fontSize: typography.caption.fontSize,
    },
    vucBadge: {
      marginTop: spacing(-0.25),
      marginRight: spacing(0.5),
    },
    truncateText: {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
    wrapperLink: {
      display: "block",
      padding: 0,
      width: "100%",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#fff",
      },
    },
    separator: {
      borderLeft: `2px solid ${palette?.appBackground?.darkGrey}`,
    },
  })
);

const ConversationCard: FunctionComponent<IProps> = ({
  isCTM,
  isArchived,
  conversation,
  idx,
  isRetchingConversation,
}): JSX.Element => {
  const { spacing, palette } = useTheme<Theme>();
  const classes = useStyles({ isArchived });
  const parentDiv = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    id,
    state,
    category,
    virtualUrgentCare,
    actionableItems,
    updatedAt,
    serviceSubtype,
    billable,
    owner,
    lastCtmUpdater,
    memberInfo,
    subject,
    subjectFreetext,
    lastUpdatedUserType,
  } = conversation;
  const { roles, hasInitialMymops, hasInitialPsfs } =
    conversation as CtmConversationSummary;
  const inCompleteActionableItems = actionableItems
    ? getInCompleteActionableItems(actionableItems)
    : [];
  const toDos = getToDo(inCompleteActionableItems);
  const formatDate = useFormatDate();
  const lastActivityTime =
    updatedAt &&
    formatDate(updatedAt, "LLL d", {
      fallbackDate: "",
      invalidateDateFallback: "",
      forConversationCard: true,
    });
  // @ts-ignore: Object is possibly 'null'.
  const messageSubject = getMessageSubject(subject, subjectFreetext);
  const user = useSelector(getUser);
  const lastCtmUpdaterName =
    lastCtmUpdater?.id === String(user?.id) ? "You" : lastCtmUpdater?.name;
  const lastActivityBy =
    memberInfo?.id === String(user?.id)
      ? "You"
      : memberInfo?.legalAndPreferredName;
  const scrollRestoration = useScrollRestoration(idx, isRetchingConversation);

  const titleText =
    conversation.category === "messaging"
      ? formatMessageSubject(messageSubject)
      : serviceSubtype;

  useEffect(() => {
    parentDiv.current && scrollRestoration.setComponent(parentDiv.current);
  }, [parentDiv.current]);

  return (
    <Link
      className={classes.wrapperLink}
      title="View Conversation Timeline"
      data-testid="conversation"
      onClick={() => {
        idx && dispatch(setComponentIdentifier(idx));
        navigate(`/members/${memberInfo?.id}/conversations/${id}`);
      }}
    >
      <div
        // @ts-ignore: Object is possibly 'null'.
        ref={parentDiv}
      >
        <Card
          className={classes.root}
          data-testid={`${state}-conversation-card`}
        >
          <CardSection
            containerStyle={{
              flexWrap: "nowrap",
            }}
          >
            <CardItem
              style={{ padding: 0, maxWidth: "65ch", display: "flex" }}
              className={classes.truncateText}
            >
              <Typography
                appearance="body"
                fontWeightBold
                component="span"
                data-testid="conversation-name"
                style={{ display: "flex" }}
              >
                {titleText}{" "}
                {category === "preventive" && (
                  <span data-testid="preventive-badge">
                    <Asset
                      name="preventive-badge"
                      hiddenFromScreenReader
                      alt=""
                    />
                  </span>
                )}
                {isCTM && (
                  <>
                    {roles?.map((role: RoleOutputCoreSchema) => (
                      <EocBadge
                        badge={{
                          abbreviation: role.abbreviation ?? "",
                          color: role.color ?? "",
                          backgroundColor: role.backgroundColor ?? "",
                        }}
                        key={role.id}
                        className={classes.eocBadge}
                      />
                    ))}
                    {virtualUrgentCare && (
                      <VucBadge className={classes.vucBadge} />
                    )}
                    {(hasInitialMymops || hasInitialPsfs) && (
                      <span className={classes.separator}></span>
                    )}
                    {hasInitialMymops && (
                      <EocBadge
                        badge={{
                          abbreviation: "MYMOPS",
                          color: palette?.white,
                          backgroundColor: palette.appBackground
                            ?.darkGrey as string,
                        }}
                        className={classes.eocBadge}
                        data-testid="mymops-batch"
                      />
                    )}
                    {hasInitialPsfs && (
                      <EocBadge
                        badge={{
                          abbreviation: "PSFS",
                          color: palette?.white,
                          backgroundColor: palette?.appBackground
                            ?.darkGrey as string,
                        }}
                        className={classes.eocBadge}
                        data-testid="psfs-batch"
                      />
                    )}
                  </>
                )}
              </Typography>
            </CardItem>
            <CardItem style={{ padding: 0 }}>
              <Box display="flex" alignItems="center">
                {(inCompleteActionableItems.length > 0 || isArchived) && (
                  <StatusBadge
                    statusInfo={
                      inCompleteActionableItems.length > 1
                        ? `${inCompleteActionableItems.length} To-Do's`
                        : "1 To-Do"
                    }
                    isArchived={isArchived}
                    isBilled={billable && isCTM}
                  />
                )}
                <Typography
                  appearance="smallBody"
                  noWrap
                  data-testid={`${id}-last-activity-time`}
                >
                  {lastActivityTime}
                </Typography>
              </Box>
            </CardItem>
          </CardSection>
          <CardSection>
            <CardItem style={{ padding: 0, maxWidth: "65ch" }}>
              <Box mt={spacing(0.25)} component="dl">
                {isCTM && owner && (
                  <InformationLine
                    title="Owner"
                    label={owner.name}
                    alt={owner.legalName ?? ""}
                    description={owner.guilds}
                    src={owner.avatarUrl ?? ""}
                  />
                )}
                {lastUpdatedUserType ===
                  ConversationSummaryCoreLastUpdatedUserTypeEnum.Ctm &&
                lastCtmUpdater ? (
                  <InformationLine
                    title="Last activity by"
                    label={lastCtmUpdaterName ?? ""}
                    alt={lastCtmUpdater.name}
                    src={lastCtmUpdater.avatarUrl ?? ""}
                    description={lastCtmUpdater.guilds}
                  />
                ) : (
                  <InformationLine
                    title="Last activity by"
                    label={lastActivityBy || ""}
                    alt={memberInfo?.legalName || ""}
                    src={memberInfo.avatarUrl ?? ""}
                  />
                )}
                {toDos.length > 0 && !isArchived && (
                  <Box display="flex">
                    <Typography
                      color="textSecondary"
                      appearance="smallBody"
                      className={classes.truncateText}
                      component="dt"
                      style={{ marginRight: "4px" }}
                    >
                      To-do's:
                    </Typography>
                    <Typography
                      color="textPrimary"
                      appearance="smallBody"
                      display="inline"
                      component="dd"
                    >
                      {toDos.length > 3
                        ? `${toDos.slice(0, 3).join(", ")} and more`
                        : toDos.join(", ")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </CardItem>
          </CardSection>
        </Card>
      </div>
    </Link>
  );
};

export default ConversationCard;
