import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { useDispatch } from "react-redux";
import {
  ScheduleAppointmentOnSlotRequest,
  fetchAppointmentsApi,
} from "shared/fetch/src/apis";
import { ComponentDetailsOutput } from "shared/fetch/src/models/ComponentDetailsOutput";
import { showSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotsQueryKey } from "./useGetSlots";
import { getFetchSlotQueryKey } from "./useGetSlot";

type ScheduleAppointmentMutationFn = (
  requestParameters: ScheduleAppointmentOnSlotRequest
) => Promise<ComponentDetailsOutput>;

const useScheduleAppointment = (
  config?: UseMutationOptions<
    ComponentDetailsOutput,
    Error,
    ScheduleAppointmentOnSlotRequest
  >
): UseMutationResult<
  ComponentDetailsOutput,
  Error,
  ScheduleAppointmentOnSlotRequest
> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const mutationFn: ScheduleAppointmentMutationFn = (
    requestParameters: ScheduleAppointmentOnSlotRequest
  ) => {
    return fetchAppointmentsApi.scheduleAppointmentOnSlot(
      requestParameters
    ) as Promise<ComponentDetailsOutput>;
  };

  const defaultConfig: UseMutationOptions<
    ComponentDetailsOutput,
    Error,
    ScheduleAppointmentOnSlotRequest
  > = {
    onSuccess: (data, variables) => {
      dispatch(showSnackbar("Slot has been successfully scheduled."));
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
      queryClient.invalidateQueries(getFetchSlotQueryKey({ id: variables.id }));
      // TODO: Invalidate the slots query to refetch the data
    },
    onError: () => {
      dispatch(showSnackbar("Failed to schedule the slot.", "danger"));
    },
    ...config,
  };

  return useMutation<
    ComponentDetailsOutput,
    Error,
    ScheduleAppointmentOnSlotRequest
  >(mutationFn, defaultConfig);
};

export default useScheduleAppointment;
