import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import {
  addMinutes,
  endOfDay,
  isAfter,
  isBefore,
  min,
  parse,
  startOfDay,
} from "date-fns";
import {
  format as formatTZ,
  utcToZonedTime,
  zonedTimeToUtc,
} from "date-fns-tz";
import MenuItem from "components/Menu/MenuItem";
import TextField from "components/TextField";
import Typography from "components/Typography";
import { SlotVisibilityEnum } from "shared/fetch/src/models/SlotVisibilityEnum";
import { timezoneOptions } from "shared/utils/timeZone";
import classes from "./index.module.css";
import {
  ValuesType,
  formatTime,
  getCalendarUiDateObjectFromParams,
  inputSxProps,
} from "./utils";

interface IProps {
  values: ValuesType;
  handleChange: (event: any) => void;
  timezone: string;
  hoursOfOperation: Record<string, [string, string]>;
  isSlotCreation: boolean;
  disabled?: boolean;
}

const serverBusinessHoursKeys: { [key: string]: string } = {
  Fr: "F",
  Su: "su",
  Th: "th",
  Sa: "S",
  Mo: "M",
  Tu: "T",
  We: "W",
};

export const generateDateRanges = (
  date: Date,
  intervalMinutes: number = 5,
  operatingHours: { [key: string]: [string, string] },
  timezone: string
): { start: Date; end: Date }[] => {
  const dayStart = startOfDay(date);
  const dayEnd = endOfDay(date);

  const dayOfWeek =
    serverBusinessHoursKeys[
    formatTZ(date, "EEEEEE", {
      timeZone: timezone,
    })
    ];

  const operatingHoursUpper = Object.keys(operatingHours).reduce(
    (acc, key) => ({
      ...acc,
      [key]: operatingHours[key],
    }),
    {}
  ) as Record<string, [string, string]>;

  if (!operatingHoursUpper[dayOfWeek]) {
    return [];
  }

  const [startTime, endTime] = operatingHoursUpper[dayOfWeek];
  const operationStart = parse(startTime, "HH:mm", dayStart);
  const operationEnd = min([parse(endTime, "HH:mm", dayStart), dayEnd]);

  const ranges: { start: string; end: string }[] = [];
  let currentTime = operationStart;

  while (isBefore(currentTime, operationEnd)) {
    const rangeEnd = min([
      addMinutes(currentTime, intervalMinutes),
      operationEnd,
    ]);
    ranges.push({
      start: formatTZ(currentTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
        timeZone: timezone,
      }),
      end: formatTZ(rangeEnd, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
        timeZone: timezone,
      }),
    });

    currentTime = rangeEnd;
    if (
      isAfter(currentTime, operationEnd) ||
      currentTime.getTime() === operationEnd.getTime()
    ) {
      break;
    }
  }

  return ranges.map((range) => ({
    start: new Date(range.start),
    end: new Date(range.end),
  }));
};

const formatTimeForDisplay = (time: string, timezone: string): string => {
  if (!time) {
    return "";
  }
  const parsedTime = utcToZonedTime(new Date(time), timezone);
  return formatTZ(parsedTime, "h:mm a", { timeZone: timezone });
};

const SelectVisitTime: FunctionComponent<IProps> = ({
  values,
  handleChange,
  timezone,
  hoursOfOperation,
  isSlotCreation,
  disabled,
}) => {
  const [searchParams] = useSearchParams();
  const initialDate =
    values.selectedDate || getCalendarUiDateObjectFromParams(searchParams);
  const [currentDate, setCurrentDate] = useState(initialDate);

  const isEndTimeDisabled =
    values.visibility !== SlotVisibilityEnum.Hold && Boolean(values.duration);

  const handleTimeChange = (event: any) => {
    const { name, value } = event.target;
    const updates = [];

    // Add the initial change
    updates.push({ name, value });

    if (name === "startAt") {
      // Update start time display
      updates.push({
        name: "startTime",
        value: formatTime(new Date(value), timezone),
      });
    } else if (name === "endAt") {
      // Update end time display
      updates.push({
        name: "endTime",
        value: formatTime(new Date(value), timezone),
      });
    }

    // Apply all updates
    updates.forEach((update) => handleChange({ target: update }));
  };

  // Effect for handling initial setup and date changes
  useEffect(() => {
    if (isSlotCreation && currentDate && (!values.startAt || !values.endAt)) {
      const zonedCurrentDate = utcToZonedTime(currentDate, timezone);
      const updates = [];

      // Always update start time when date changes
      updates.push({
        name: "startAt",
        value: zonedTimeToUtc(zonedCurrentDate, timezone).toISOString(),
      });
      updates.push({
        name: "startTime",
        value: formatTime(zonedCurrentDate, timezone),
      });

      // Update selected date if not set
      if (!values.selectedDate) {
        updates.push({
          name: "selectedDate",
          value: currentDate,
        });
      }

      // Always update end time when date changes
      const endDate = addMinutes(zonedCurrentDate, values.duration || 30);
      updates.push({
        name: "endAt",
        value: zonedTimeToUtc(endDate, timezone).toISOString(),
      });
      updates.push({
        name: "endTime",
        value: formatTime(endDate, timezone),
      });

      // Apply all updates
      updates.forEach((update) => {
        handleChange({ target: update });
      });
    }
  }, [
    currentDate,
    timezone,
    isSlotCreation,
    values.selectedDate,
    values.duration,
    values.startAt,
    values.endAt,
  ]);

  // Effect to handle duration-based end time updates
  useEffect(() => {
    if (
      values?.duration &&
      values?.startAt &&
      values?.appointmentTypes?.length! > 0
    ) {
      const startDate = utcToZonedTime(new Date(values.startAt), timezone);
      const endDate = addMinutes(startDate, values.duration);
      const newEndAt = zonedTimeToUtc(endDate, timezone).toISOString();

      if (newEndAt !== values.endAt) {
        handleChange({
          target: {
            name: "endAt",
            value: newEndAt,
          },
        });
        handleChange({
          target: {
            name: "endTime",
            value: formatTime(new Date(newEndAt), timezone),
          },
        });
      }
    }
  }, [values.duration, values.startAt, values.appointmentTypes]);

  useEffect(() => {
    if (values.selectedDate) {
      setCurrentDate(values.selectedDate);

      if (values.selectedDate) {
        // default to clinic open time if no time is selected
        const defaultTime =
          hoursOfOperation[
          serverBusinessHoursKeys[
          formatTZ(values.selectedDate, "EEEEEE", {
            timeZone: timezone,
          })
          ]
          ]?.[0];

        if (!defaultTime) {
          return;
        }

        const newStartAt = zonedTimeToUtc(
          parse(defaultTime, "HH:mm", values.selectedDate),
          timezone
        ).toISOString();

        handleChange({
          target: {
            name: "startAt",
            value: newStartAt,
          },
        });
        handleChange({
          target: {
            name: "startTime",
            value: formatTime(values.selectedDate, timezone),
          },
        });

        const endDate = addMinutes(values.selectedDate, values.duration || 30);
        const newEndAt = zonedTimeToUtc(endDate, timezone).toISOString();
        handleChange({
          target: {
            name: "endAt",
            value: newEndAt,
          },
        });
        handleChange({
          target: {
            name: "endTime",
            value: formatTime(endDate, timezone),
          },
        });
      }
    }
  }, [values.selectedDate]);

  const timeOptions: string[] = generateDateRanges(
    currentDate,
    5,
    hoursOfOperation,
    timezone
  ).map((range) => range.start.toISOString());

  return (
    <>
      <div className={classes.numberInputRow}>
        <TextField
          name="startAt"
          disabled={
            (values?.appointments && values?.appointments?.length > 0) ||
            disabled
          }
          classes={{
            root: clsx(
              classes.standardHeightTextField,
              classes.timeTextField,
              classes.textField
            ),
          }}
          sx={inputSxProps}
          inputProps={{ "aria-label": "Visit start time" }}
          value={values?.startAt || ""}
          select
          onChange={handleTimeChange}
          SelectProps={{
            renderValue: (value) =>
              value ? formatTimeForDisplay(value as string, timezone) : "",
            MenuProps: { sx: { height: "325px" } },
          }}
        >
          {timeOptions.map((time: string, index: number) => (
            <MenuItem
              key={`${index}-start`}
              value={time}
              data-time={formatTimeForDisplay(time, timezone)}
              // autofocus is fine to use in this case and doesn't introduce an a11y barrier
              // for good UX the selected time is focused/scrolled to when the dropdown is opened
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={
                formatTimeForDisplay(time, timezone) === values.startTime
              }
            >
              {formatTimeForDisplay(time, timezone)}
            </MenuItem>
          ))}
        </TextField>

        <Typography
          appearance="body"
          className={clsx(classes.to, classes.text)}
        >
          to
        </Typography>

        <TextField
          name="endAt"
          classes={{
            root: clsx(
              classes.timeTextField,
              classes.textField,
              classes.standardHeightTextField
            ),
          }}
          sx={{ marginRight: "4px", ...inputSxProps }}
          inputProps={{ "aria-label": "Visit end time" }}
          value={values?.endAt || ""}
          select
          onChange={handleTimeChange}
          SelectProps={{
            renderValue: (value) =>
              value ? formatTimeForDisplay(value as string, timezone) : "",
            MenuProps: { sx: { height: "325px" } },
          }}
          disabled={
            (values?.appointments && values?.appointments?.length > 0) ||
            isEndTimeDisabled ||
            disabled
          }
        >
          {timeOptions.map((time: string, index: number) => (
            <MenuItem
              key={`${index}-end`}
              value={time}
              data-time={formatTimeForDisplay(time, timezone)}
              // autofocus is fine to use in this case and doesn't introduce an a11y barrier
              // for good UX the selected time is focused/scrolled to when the dropdown is opened
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={formatTimeForDisplay(time, timezone) === values.endAt}
            >
              {formatTimeForDisplay(time, timezone)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="timezone"
          inputProps={{ "aria-label": "Timezone" }}
          classes={{
            root: clsx(
              classes.standardHeightTextField,
              classes.timezoneTextField,
              classes.textField
            ),
          }}
          sx={{
            "& .MuiInputBase-root": {
              padding: "0",
              border: "1px solid",
              borderRadius: "10px",
            },
          }}
          value={
            timezoneOptions.find((option) => option.timezone === timezone)
              ?.name ?? timezone
          }
          disabled
        />
      </div>
    </>
  );
};

export default SelectVisitTime;
