import React, { FunctionComponent } from "react";
import classes from "./index.module.css";
import Typography from "components/Typography";
import TextField from "components/TextField";
import clsx from "clsx";
import { inputSxProps } from "./utils";
interface IProps {
  values: any;
  handleChange: any;
  disabled?: boolean;
  setIsKeepOrCancelFlow: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectNumberOfMembers: FunctionComponent<IProps> = ({
  values,
  handleChange,
  disabled,
  setIsKeepOrCancelFlow,
}) => {
  const maxPatientsLabel = "Members allowed";
  const maxOverbookLabel = "Maximum of Members over-scheduled";

  return (
    <>
      <div className={classes.numberInputRow}>
        <TextField
          name="maxPatients"
          classes={{ root: clsx(classes.numberInput, classes.textField) }}
          InputProps={{
            inputProps: {
              max: 30,
              min: 1,
              "aria-label": maxPatientsLabel,
            },
          }}
          type="number"
          value={values?.appointmentTypes?.length > 1 ? 1 : values?.maxPatients}
          onChange={(e) => {
            const event = {
              ...e,
              target: {
                name: "maxPatients",
                value: parseInt(e.target.value, 10),
              },
            };

            // sum total of maxPatients and maxOverbook current values
            const totalGroup =
              values?.maxOverbook + parseInt(e.target.value, 10);
            // check if the number of appointments booked is greater than the total group
            if (values?.appointments?.length > totalGroup) {
              // if it is, then we go into the keep or cancel flow
              setIsKeepOrCancelFlow(true);
            } else {
              setIsKeepOrCancelFlow(false);
            }

            handleChange(event);
          }}
          disabled={values?.appointmentTypes?.length > 1 || disabled}
          sx={inputSxProps}
        />
        <Typography appearance="body" className={classes.text}>
          {maxPatientsLabel}
        </Typography>
      </div>
      <div className={classes.numberInputRow}>
        <TextField
          name="maxOverbook"
          classes={{ root: clsx(classes.numberInput, classes.textField) }}
          InputProps={{
            inputProps: {
              max: 10,
              min: 0,
              "aria-label": maxOverbookLabel,
            },
          }}
          type="number"
          value={values?.maxOverbook}
          disabled={values?.appointmentTypes?.length > 1 || disabled}
          onChange={(e) => {
            const event = {
              ...e,
              target: {
                name: "maxOverbook",
                value: parseInt(e.target.value, 10),
              },
            };

            // sum total of maxPatients and maxOverbook current values
            const totalGroup =
              values?.maxPatients + parseInt(e.target.value, 10);

            // check if the number of appointments booked is greater than the total group
            if (values?.appointments?.length > totalGroup) {
              // if it is, then we go into the keep or cancel flow
              setIsKeepOrCancelFlow(true);
            } else {
              setIsKeepOrCancelFlow(false);
            }

            handleChange(event);
          }}
          sx={inputSxProps}
        />{" "}
        <Typography appearance="body" className={classes.text}>
          {maxOverbookLabel}
        </Typography>
      </div>
    </>
  );
};

export default SelectNumberOfMembers;
