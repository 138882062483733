import {
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  ViewApi,
  DateSpanApi,
} from "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import FullCalendar from "@fullcalendar/react";
import { ResourceApi } from "@fullcalendar/resource";
import dayGridPlugin from "@fullcalendar/resource-daygrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Avatar from "components/Avatar";
import {
  addDays,
  format,
  getDay,
  isWithinInterval,
  lastDayOfWeek,
  startOfWeek,
} from "date-fns";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import { GetSlotsForDateOutputWithArray } from "shared/api/src/models/GetSlotsForDateOutputWithArray";
import { XOCalProvider } from "shared/api/src/models/XOCalProvider";
import useClinicHours from "shared/features/xocal/useClinicHours";
import useCreateSlot from "shared/features/xocal/useCreateSlot";
import useGetCalendarStaticData from "shared/features/xocal/useGetCalendarStaticData";
import useGetSlots, {
  getFetchSlotsQueryKey,
} from "shared/features/xocal/useGetSlots";
import { useModalitiesWithColors } from "shared/features/xocal/useGetVisitTypes";
import XOCalSkeleton from "components/Skeleton/XOCalSkeleton";
import useUpdateSlot from "shared/features/xocal/useUpdateSlot";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { SlotVisibilityEnum } from "shared/fetch/src/models/SlotVisibilityEnum";
import EventContent from "./EventContent";
import ProviderBadge from "./ProviderBadge";
import SlotActionDrawer from "./SlotActionDrawer";
import "./style.css";
import {
  DAY_VIEW,
  getEventClassNames,
  WEEK_VIEW,
  ALL_PROVIDERS,
  ACTIVE_PROVIDERS,
  ALL_TYPES,
  getIsClinicOpen,
} from "./utils";
import XOCalHeader from "./XOCalHeader";
import useGetOOO from "shared/features/xocal/useGetOOO";
import { format as formatTZ, utcToZonedTime } from "date-fns-tz";
import ReactDOM, { createPortal } from "react-dom";
import { OOOBadge, OOOBadgeProps } from "./OOOBadge";
import NoActiveProvidersMessage from "./NoActiveProvidersMessage";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import { JsonUser } from "shared/fetch/src/models/JsonUser";
import { clearSnackbar, showSnackbar } from "shared/state/ui/snackbar";
import { hasDatePassed } from "./SlotActionDrawer/utils";
import { setDefaultTimeZone } from "shared/utils/timeZone";
import TimezoneDisplay from "./TimezoneDisplay";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarContainer: { justifyContent: "center", marginBottom: "10px" },
    avatarLink: {
      textDecoration: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: theme?.palette.appBackground?.darkestGray,
      background: theme?.palette.appBackground?.lightestGray,
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    eventContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "1px",
    },
    titleDisplayContainer: {
      padding: "6px",
      margin: "3px",
      borderRadius: "5px",
      backgroundColor: "#FFFFFF",
      fontSize: "10px",
      height: "100%",
    },
    titleAndDate: {
      display: "flex",
      justifyContent: "space-between",
    },
    titleTrunaction: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "65%",
    },
    minuteText: {
      color: theme.palette.appBackground?.medGrey,
    },
    visibilityIcon: {
      fontSize: "10px",
      marginRight: "3px",
    },
    groupIconTextContainer: {
      marginTop: "5px",
      display: "flex",
      justifyContent: "space-between",
      fontSize: "10px",
    },
    groupIconContainer: {
      width: "20%",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#E3F4DD",
    },
    groupIconContainerFullGroup: {
      width: "20%",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FFF2F8",
    },
    groupIcon: { fontSize: "10px", marginRight: "3px" },
    providerName: {
      fontSize: "0.8rem",
      fontWeight: "lighter",
    },
  })
);

interface IProps {
  inputEvents: any;
  schedulerProviders: XOCalProvider[];
}

interface TimeZone {
  timezone: string;
  name: string;
}

const XOCal = React.memo(({ schedulerProviders }: IProps) => {
  const classes = useStyles();
  const calendarRef = useRef<FullCalendar | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateTitle, setDateTitle] = React.useState<string>();
  const [selectedSlotID, setSelectedSlotID] = React.useState<string | null>(
    null
  );
  const dispatch = useDispatch();
  const user = useSelector(getUser) as JsonUser;
  const hasFullXoCalPermissions = Boolean(user.hasFullXoCalPermissions);

  const queryClient = useQueryClient();

  const view = searchParams.get("view");
  const providerId = searchParams.get("providerId");
  const clinicId = searchParams.get("clinicId");
  const date = searchParams.get("date");

  const allOrActive =
    providerId === ACTIVE_PROVIDERS || providerId === ALL_PROVIDERS;

  const currentProvider = useMemo(
    () =>
      schedulerProviders.find((provider) => provider.providerId === providerId),
    [schedulerProviders, providerId]
  );

  const [slotProvider, setSlotProvider] = useState<XOCalProvider | undefined>(
    currentProvider
  );

  React.useEffect(() => {
    setSlotProvider(currentProvider);
  }, [currentProvider]);

  const { weekStart, weekEnd } = useMemo(() => {
    if (view === WEEK_VIEW) {
      return {
        weekStart: format(
          startOfWeek(new Date(date!), { weekStartsOn: 6 }),
          "yyyy-MM-dd"
        ),
        weekEnd: format(
          addDays(lastDayOfWeek(new Date(date!), { weekStartsOn: 6 }), 1),
          "yyyy-MM-dd"
        ),
      };
    } else {
      return { weekStart: date!, weekEnd: date! };
    }
  }, [view, date, calendarRef.current]);

  // if the providerID is for all or active providers and the user is in week_view, send the user back to day_view
  if (view === WEEK_VIEW && allOrActive) {
    searchParams.delete("view");
    searchParams.append("view", DAY_VIEW);
    setSearchParams(searchParams);
  }

  const slotsParams = useMemo(
    () => ({
      clinicId: clinicId as string,
      providerId: !allOrActive && providerId ? providerId : undefined,
      start: weekStart,
      end: weekEnd,
    }),
    [clinicId, providerId, weekStart, weekEnd, allOrActive]
  );

  const { data: slots, isLoading } = useGetSlots(slotsParams);

  const oooParams: { providerIds?: string[]; clinicIds?: string[] } = {};

  if (clinicId && allOrActive) {
    delete oooParams.providerIds;
    oooParams.clinicIds = [clinicId];
  } else if (providerId) {
    delete oooParams.clinicIds;
    oooParams.providerIds = [providerId];
  }

  const { data: oooInformation } = useGetOOO(oooParams);

  // styling for ooo information, including badge
  const oooDays: Set<{ start: ""; end: ""; provider: ""; id: "" }> = new Set();

  oooInformation &&
    // @ts-ignore
    oooInformation.forEach((info) =>
      oooDays.add({
        start: info.startDate,
        end: info.endDate,
        provider: info.providerId,
        id: info.id,
      })
    );

  const getOOODayInfo = useCallback(
    (_date: Date, _providerId: string) => {
      let oooDay;
      if (!allOrActive) {
        oooDay = Array.from(oooDays).filter(
          (day) =>
            day.provider === _providerId &&
            isWithinInterval(_date, {
              start: new Date(day.start),
              end: new Date(day.end),
            })
        );
      } else if (allOrActive) {
        oooDay = Array.from(oooDays).filter((day) =>
          isWithinInterval(_date, {
            start: new Date(day.start),
            end: new Date(day.end),
          })
        );
      }

      if (oooDay) {
        return oooDay.map((day) => {
          return {
            id: day.id,
            providerId: day.provider,
            start: day.start,
            end: day.end,
          };
        });
      } else {
        return null;
      }
    },
    [oooDays]
  );

  const handleDayCellDidMount = useCallback(
    (arg: { date: Date; el: HTMLElement }) => {
      const { date: cellDate, el } = arg;
      const resourceId = el.getAttribute("data-resource-id");
      const checkProviderId = (allOrActive ? null : providerId) || resourceId;

      if (checkProviderId) {
        const oooInfo = getOOODayInfo(cellDate, checkProviderId);
        oooInfo?.forEach((day) => {
          if (
            resourceId === day?.providerId ||
            checkProviderId === day?.providerId
          ) {
            el.classList.add("ooo-background");
          }
        });
      }
    },
    [providerId, getOOODayInfo]
  );

  React.useEffect(() => {
    const searchParamsConflicts = slots?.conflicts?.toString() || "0";
    setSearchParams((prev) => {
      prev.delete("conflicts");
      prev.append("conflicts", searchParamsConflicts);
      return prev;
    });
  }, [slots?.conflicts, setSearchParams]);

  const { mutateAsync: updateSlot } = useUpdateSlot({
    onMutate: async (core) => {
      await queryClient.cancelQueries({
        queryKey: getFetchSlotsQueryKey(slotsParams),
      });

      const previousSlots: GetSlotsForDateOutputWithArray | undefined =
        queryClient.getQueryData(getFetchSlotsQueryKey(slotsParams));

      const newSlots = {
        ...previousSlots,
        results: previousSlots?.results?.map((slot: SlotOutput) => {
          if (slot.id !== Number(core.id)) {
            return slot;
          }

          return {
            ...slot,
            startAt: core?.createSlotCore?.startAt,
            endAt: core?.createSlotCore?.endAt,
          };
        }),
      };
      queryClient.setQueryData(getFetchSlotsQueryKey(slotsParams), () => {
        return newSlots;
      });
      return { previousSlots, newSlots };
    },
    onError: (_err, _core, context) => {
      queryClient.setQueryData(
        getFetchSlotsQueryKey(slotsParams),
        // @ts-ignore
        context.previousSlots
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getFetchSlotsQueryKey(slotsParams),
      });
    },
  });

  const { data: calendarStaticData } = useGetCalendarStaticData({
    clinicId: clinicId?.toString() as string,
  });

  const modalityColors = useModalitiesWithColors();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const clinicOperatingData = useClinicHours(clinicId, date);

  const { businessHours, operatingDaysNumerical, expandedTimes } =
    useClinicHours(clinicId);

  // TODO: rename to clinicTimezone for clarity
  // additionally, it feels like if we have a useCinicHours hook the clinic
  // timezone could be returned from it
  const timezone = calendarStaticData?.clinicDetails?.timeZone;

  const events =
    slots?.results?.map((slot) => {
      // @ts-ignore
      const color = modalityColors?.[slot?.appointmentType!];
      return {
        resourceId: slot.providerId,
        title: "",
        start: !!slot?.appointments?.length
          ? slot.appointments[0].startAt
          : slot.startAt,
        end: !!slot?.appointments?.length
          ? slot.appointments[0].endAt
          : slot.endAt,
        appointmentTypes: slot.appointmentTypes,
        visibility: slot.visibility,
        clinicTimeZone: slot.clinicTimeZone,
        backgroundColor: color,
        ...slot,
        id: slot.id?.toString(),
        editable: slot.appointments && slot?.appointments?.length < 1,
        durationEditable: false,
      };
    }) || [];

  const activeProviders: XOCalProvider[] = useMemo(() => {
    const providersWithEvents: string[] = [];
    events?.forEach((event) => {
      if (event.providerId && !providersWithEvents.includes(event.providerId)) {
        providersWithEvents.push(event.providerId);
      }
    });

    return schedulerProviders.filter((provider) => {
      return (
        provider.providerId && providersWithEvents.includes(provider.providerId)
      );
    });
  }, [events, schedulerProviders]);

  const allProviderResources = useMemo(
    () =>
      schedulerProviders.map((provider) => ({
        ...provider,
        id: provider.providerId,
        title: provider.flat,
      })),
    [schedulerProviders]
  );

  const activeProviderResources = useMemo(
    () =>
      activeProviders.map((provider) => ({
        ...provider,
        id: provider.providerId,
        title: provider.flat,
      })),
    [activeProviders]
  );

  const headerDateChange = () => {
    calendarRef.current?.getApi().getDate() &&
      searchParams.set(
        "date",
        format(
          //@ts-ignore
          utcToZonedTime(calendarRef.current?.getApi().getDate(), timezone),
          "yyyy-MM-dd"
        )
      );
    setSearchParams(searchParams);
    setDateTitle(calendarRef.current?.getApi().view.title);
  };

  // needed for styling first time label on the time axis
  const firstTimeLabel = document.getElementsByClassName(
    "fc-timegrid-slot-label-cushion"
  )[0];
  React.useEffect(() => {
    firstTimeLabel && firstTimeLabel.setAttribute("id", "first-time-label");
  }, [firstTimeLabel]);

  // set params and use params to change view and date
  React.useEffect(() => {
    if (!view && (!providerId || providerId === ALL_PROVIDERS) && !clinicId) {
      searchParams.append("view", DAY_VIEW);
    } else if (!date && calendarRef.current) {
      searchParams.set(
        // should next 3 loc be the way we always refer to the date?
        "date",
        formatTZ(new Date(), "yyyy-MM-dd", {
          timeZone: timezone,
        })
      );
    } else if (!searchParams.get("providerId")) {
      searchParams.append("providerId", ACTIVE_PROVIDERS);
    } else if (!searchParams.get("serviceType")) {
      searchParams.append("serviceType", ALL_TYPES);
    }

    view && calendarRef.current?.getApi().changeView(view);
    date && calendarRef.current?.getApi().gotoDate(date);
    setDateTitle(calendarRef.current?.getApi().view.title);
    setSearchParams(searchParams);
  }, [view, date, dateTitle, clinicId, timezone]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isSlotCreation, setIsSlotCreation] = useState(false);

  const openDrawer = (creatingSlot: boolean) => {
    if (creatingSlot) {
      setIsSlotCreation(true);
    } else {
      setIsSlotCreation(false);
    }
    setDrawerOpen(true);
  };

  const onClose = () => {
    setSelectedSlotID(null);
    setDrawerOpen(false);

    // reset slot provider
    setSlotProvider(currentProvider);
    searchParams.delete("slotActionDrawerProvider");
    searchParams.delete("member");
    setSearchParams(searchParams);
  };

  const handleClickEvent = (event: EventClickArg) => {
    const slotId = event.event.id;
    const providerIdFromEvent = event.event.extendedProps.providerId;
    searchParams.append("slotActionDrawerProvider", providerIdFromEvent);
    setSearchParams(searchParams);
    setSelectedSlotID(slotId);
    openDrawer(false);

    setSlotProvider(
      schedulerProviders.filter((p) => p.providerId === providerId).pop()
    );
  };

  const renderResourceContent = useCallback(
    (resourceInfo: { date: Date; resource: ResourceApi; view: ViewApi }) => {
      const provider = resourceInfo?.resource?.extendedProps;
      const name = provider?.name;
      const avatarUrl = provider?.shot;

      const safeUrlParams = new URLSearchParams({
        view: WEEK_VIEW,
        providerId: provider?.providerId,
        slotActionDrawerProvider: provider?.providerId,
        clinicId: clinicId as string,
      });

      return (
        <Link
          data-testid="day-view-avatar"
          color="inherit"
          className={classes.avatarLink}
          to={`/calendar?${safeUrlParams}`}
        >
          <Avatar
            className={classes.avatarContainer}
            alt={name}
            src={avatarUrl}
            size={"medium"}
          />
          <div className={classes.providerName} data-testid="provider-name">
            {name}
            <ProviderBadge
              serviceType={provider.serviceType}
              roles={calendarStaticData?.roles}
            />
          </div>
        </Link>
      );
    },
    [classes, WEEK_VIEW, clinicId, calendarStaticData]
  );

  const renderEventContent = useCallback(
    (eventInfo: EventContentArg) => {
      return (
        <EventContent
          eventInfo={eventInfo}
          timezone={timezone!}
          calendarStaticData={calendarStaticData}
        />
      );
    },
    [timezone, events]
  );

  React.useEffect(() => {
    if (!isLoading) {
      // the full calendar package includes a div with no role but an aria-labelledby by attr which points to a non-existent id
      // so we need to remove the aria-labelledby attr since it is invalid
      const divWithInvalidAria =
        document.getElementsByClassName("fc-view-harness")[0];
      divWithInvalidAria?.removeAttribute("aria-labelledby");

      const scrollableDivWithoutTabIndex = document.getElementsByClassName(
        "fc-scroller-liquid-absolute"
      )[0];
      scrollableDivWithoutTabIndex?.setAttribute("tabindex", "0");
    }
  }, [isLoading]);

  const { mutateAsync: createSlot } = useCreateSlot({
    onSettled: (data) => {
      setSelectedSlotID(data?.id?.toString()!);
    },
  });

  const isDuringClinicOperatingHours = (proposedDateTime: string): boolean => {
    const clinicTimeZone = calendarStaticData?.clinicDetails?.timeZone || "";
    const proposedDateInClinicTZ = utcToZonedTime(
      new Date(proposedDateTime),
      clinicTimeZone
    );
    const dayOfWeek = getDay(proposedDateInClinicTZ);

    // Find the business hours for this day
    const dayBusinessHours = businessHours?.find((hours) =>
      hours.daysOfWeek.includes(dayOfWeek)
    );

    if (!dayBusinessHours) {
      return false;
    }

    // Create Date objects for start and end times on the same day
    const [startHour, startMinute] = dayBusinessHours.startTime
      .split(":")
      .map(Number);
    const [endHour, endMinute] = dayBusinessHours.endTime
      .split(":")
      .map(Number);

    const businessStart = new Date(proposedDateInClinicTZ);
    businessStart.setHours(startHour, startMinute, 0, 0);

    const businessEnd = new Date(proposedDateInClinicTZ);
    businessEnd.setHours(endHour, endMinute, 0, 0);

    const proposedTime = proposedDateInClinicTZ.getTime();

    return (
      proposedTime >= businessStart.getTime() &&
      proposedTime < businessEnd.getTime()
    );
  };

  const handleMoveSlot = async (movedEvent: EventDropArg) => {
    const canSlotBeMoved =
      !isDuringClinicOperatingHours(movedEvent.event.startStr) ||
      !hasDatePassed(movedEvent.event.startStr);
    if (!canSlotBeMoved) {
      movedEvent.revert();
    } else {
      const provider = currentProvider?.providerId;
      calendarRef.current
        ?.getApi()
        .getEventById(movedEvent.event.id)
        ?.setProp("start", movedEvent.event.startStr);
      calendarRef.current
        ?.getApi()
        .getEventById(movedEvent.event.id)
        ?.setProp("end", movedEvent.event.endStr);

      await updateSlot(
        {
          id: movedEvent.event.id,
          createSlotCore: {
            clinicId: clinicId!,
            providerId: provider!,
            startAt: movedEvent.event.startStr,
            endAt: movedEvent.event.endStr,
          },
        },
        {}
      );
    }
  };

  const handleDateSelect = async (selectInfo: DateSelectArg) => {
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();

    // return if drag action resulted in a slot shorter than 10 minutes
    if (
      new Date(selectInfo.endStr).getTime() -
        new Date(selectInfo.startStr).getTime() <
      600000
    ) {
      return;
    }

    if (!currentProvider?.providerId && !selectInfo.resource) {
      return;
    }

    if (!clinicId) {
      return;
    }

    const provider = currentProvider?.providerId || selectInfo?.resource?.id;

    const slotDetails = {
      clinicId: clinicId.toString(),
      providerId: provider || "",
      startAt: selectInfo.startStr,
      endAt: selectInfo.endStr,
      appointments: [],
      appointmentTypes: [],
      visibility: SlotVisibilityEnum.Hold,
      maxOverbook: 0,
      maxPatients: 1,
      restricted_to: null,
    };

    calendarApi.setOption("selectable", false);
    setSlotProvider(
      schedulerProviders.filter((p) => p.providerId === provider).pop()
    );

    calendarApi.addEvent({
      ...slotDetails,
      id: "temp",
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      resourceId: provider,
      creating: true,
    });

    createSlot({
      createSlotCore: {
        ...slotDetails,
      },
    });

    calendarApi.getEventById("temp")?.remove();
    calendarApi.setOption("selectable", true);
  };

  const scrollTime = new Date().toTimeString().split(" ")[0];

  const isClinicOpen = getIsClinicOpen(date, operatingDaysNumerical, timezone);

  // TODO: figure out the logic to disable select
  // If the provider is out-of-office OR the date/time selected is outside of the clinics operating hours, return true.
  const handleAllowSelect = (event: DateSpanApi): boolean => {
    const provider = event.resource?.id || providerId;
    const oooArray = Array.from(oooDays);
    const clinicTimeZone = calendarStaticData?.clinicDetails?.timeZone || "";

    const eventStartInClinicTZ = utcToZonedTime(event.start, clinicTimeZone);
    const eventEndInClinicTZ = utcToZonedTime(event.end, clinicTimeZone);

    if (hasDatePassed(event.start.toISOString())) {
      dispatch(showSnackbar(`Cannot create slots in the past`, "danger"));
      return false;
    }

    // Check if the provider is out-of-office
    const isOOO = oooArray.some(
      (day) =>
        day.provider === provider &&
        isWithinInterval(eventStartInClinicTZ, {
          start: utcToZonedTime(new Date(day.start), clinicTimeZone),
          end: utcToZonedTime(addDays(new Date(day.end), 1), clinicTimeZone),
        })
    );

    if (isOOO) {
      return false;
    }

    // Get the day of week (0-6) for the event start
    const dayOfWeek = getDay(eventStartInClinicTZ);

    // Find the business hours for this day
    const dayBusinessHours = businessHours?.find((hours) =>
      hours.daysOfWeek.includes(dayOfWeek)
    );

    if (!dayBusinessHours) {
      return false;
    }

    // Convert event time to hours and minutes for comparison
    const eventStartTime = formatTZ(eventStartInClinicTZ, "HH:mm", {
      timeZone: clinicTimeZone,
    });
    const eventEndTime = formatTZ(eventEndInClinicTZ, "HH:mm", {
      timeZone: clinicTimeZone,
    });

    // Check if the event time is within business hours
    const isWithinBusinessHours =
      eventStartTime >= dayBusinessHours.startTime &&
      eventEndTime <= dayBusinessHours.endTime;

    if (!isWithinBusinessHours) {
      dispatch(
        showSnackbar(`Selected time is outside of business hours`, "danger")
      );
      setTimeout(() => dispatch(clearSnackbar()), 3000);
      return false;
    }

    return true;
  };

  const oooBadgesRef = useRef<Map<string, OOOBadgeProps>>(new Map());
  const updateOOOBadges = useCallback(() => {
    const newBadges = new Map<string, OOOBadgeProps>();
    const dayElements = document.querySelectorAll(
      ".fc-daygrid-day, .fc-timegrid-col"
    );

    dayElements.forEach((el: Element) => {
      const cellDate = new Date(el.getAttribute("data-date") || "");
      const resourceId = el.getAttribute("data-resource-id");
      const providerIdToUse = !allOrActive ? providerId : resourceId;
      const badgeKey = `${providerIdToUse}-${cellDate.toISOString()}`;

      if (providerIdToUse) {
        const oooInfo =
          providerIdToUse && getOOODayInfo(cellDate, providerIdToUse);

        const providerIsOOO =
          oooInfo &&
          oooInfo.some((day) => {
            return day.providerId === providerIdToUse;
          });

        if (oooInfo && providerIsOOO) {
          oooInfo?.forEach((day) => {
            if (providerIdToUse === day?.providerId) {
              el.classList.add("ooo-background");
              newBadges.set(badgeKey, {
                el: el as HTMLElement,
                width: !allOrActive ? "14%" : "10%",
                providerId: providerIdToUse,
                date: cellDate,
                oooInfo: {
                  id: day.id,
                  start: day.start,
                  end: day.end,
                  providerId: day.providerId,
                },
                allProviderResources,
              });
            }
          });
        } else {
          el.classList.remove("ooo-background");
          // Remove the badge if it exists
          const existingBadge = oooBadgesRef.current.get(badgeKey);
          if (existingBadge) {
            const badgeElement = existingBadge.el.querySelector(".ooo-badge");
            if (badgeElement) {
              badgeElement.remove();
            }
          }
        }
      }
    });

    oooBadgesRef.current = newBadges;
  }, [providerId, oooDays, getOOODayInfo]);

  React.useEffect(() => {
    updateOOOBadges();
  }, [updateOOOBadges]);

  const renderOOOBadges = useMemo(() => {
    return Array.from(oooBadgesRef.current.values()).map(
      ({ el, width, date: ooDate, providerId: pid, oooInfo }) => {
        return createPortal(
          <OOOBadge
            key={`${pid}-${ooDate.toISOString()}`}
            width={width}
            providerId={pid}
            date={ooDate}
            oooInfo={oooInfo}
            el={el}
            allProviderResources={allProviderResources}
          />,
          el
        );
      }
    );
  }, [oooBadgesRef.current, oooDays]);

  let defaultTimeZone: TimeZone;
  if (calendarStaticData?.clinicDetails?.timeZone) {
    defaultTimeZone = setDefaultTimeZone(
      calendarStaticData?.clinicDetails?.timeZone
    );
  }

  React.useEffect(() => {
    const timezoneDisplayExists = document.querySelector(".timezoneDisplay");
    if (timezoneDisplayExists) {
      return;
    }

    const timegridTopDiv = document.querySelector(".fc-timegrid-axis-frame");
    const timegridDiv = document.querySelector("colgroup col");

    if (timegridDiv) {
      timegridDiv.style.width = "90px";
    }

    if (timegridTopDiv) {
      timegridTopDiv.style.height = "100%";
    }

    if (timegridTopDiv && defaultTimeZone) {
      const customDiv = document.createElement("div");
      timegridTopDiv.prepend(customDiv);
      ReactDOM.render(
        <TimezoneDisplay
          defaultTimeZone={defaultTimeZone}
          className={"timezoneDisplay"}
        />,
        customDiv
      );
    }
  }, [isLoading, view, calendarStaticData?.clinicDetails?.timeZone]);

  return (
    <>
      <XOCalHeader
        currentProvider={currentProvider}
        onClickPrev={() => {
          calendarRef.current?.getApi().prev();
          headerDateChange();
        }}
        onClickNext={() => {
          calendarRef.current?.getApi().next();
          headerDateChange();
        }}
        onClickToday={() => {
          calendarRef.current?.getApi().today();
          headerDateChange();
        }}
        isXoCalHeader
        viewTitle={dateTitle}
        setIsSlotCreation={setIsSlotCreation}
        onClickCreateSlotButton={() => openDrawer(true)}
        openDrawer={openDrawer}
        oooDays={oooDays}
        isClinicOpen={isClinicOpen}
        hasFullXoCalPermissions={hasFullXoCalPermissions}
      />
      <div className="xocal-container">
        <div
          className={`xocal-calendar ${
            isLoading ? "xocal-calendar--loading" : ""
          }`}
        >
          {calendarStaticData?.clinicDetails?.timeZone && (
            <FullCalendar
              select={handleDateSelect}
              selectAllow={(event) => handleAllowSelect(event)}
              selectMirror
              dayCellDidMount={(arg) => {
                handleDayCellDidMount(arg);
                updateOOOBadges();
              }}
              handleWindowResize
              ref={(node: any) => {
                calendarRef.current = node;
                if (calendarRef.current && !dateTitle) {
                  setDateTitle(calendarRef.current?.getApi().view.title);
                }
              }}
              slotDuration={"00:05:00"}
              selectMinDistance={10}
              slotLabelInterval={"00:30:00"}
              scrollTime={scrollTime}
              nowIndicator
              plugins={[
                resourceTimeGridPlugin,
                interactionPlugin,
                dayGridPlugin,
                momentTimezonePlugin,
                scrollGridPlugin,
              ]}
              dayMinWidth={240}
              // @ts-ignore
              schedulerLiscenseKey="CC-Attribution-NonCommercial-NoDerivatives"
              initialView={DAY_VIEW}
              events={events || []}
              timeZone={timezone}
              height="100%"
              eventTextColor="#000000"
              eventContent={renderEventContent}
              eventClick={(event) => handleClickEvent(event)}
              eventClassNames={getEventClassNames}
              eventDrop={handleMoveSlot}
              eventInteractive
              resources={
                providerId === ALL_PROVIDERS
                  ? allProviderResources
                  : activeProviderResources
              }
              resourceLabelContent={renderResourceContent}
              resourceOrder={"title"}
              allDaySlot={false}
              expandRows
              slotMinTime={expandedTimes.expandedStart}
              slotMaxTime={expandedTimes.expandedEnd}
              headerToolbar={{
                left: "",
                center: "",
                right: "",
              }}
              editable={false}
              selectable
              scrollTimeReset={false}
              slotMinWidth={500}
              eventMinWidth={500}
              slotEventOverlap={false}
              eventStartEditable
              businessHours={businessHours}
            />
          )}
          {renderOOOBadges}
        </div>

        <div
          className={`xocal-skeleton-overlay ${
            isLoading ? "xocal-skeleton-overlay--visible" : ""
          }`}
        >
          <XOCalSkeleton />
        </div>

        <div
          className={`xocal-no-provider-overlay ${
            providerId === ACTIVE_PROVIDERS &&
            activeProviders.length === 0 &&
            !isLoading
              ? "xocal-no-provider-overlay--visible"
              : ""
          }`}
        >
          <NoActiveProvidersMessage />
        </div>
      </div>

      {clinicId && drawerOpen && (
        <SlotActionDrawer
          open={drawerOpen}
          isSlotCreation={isSlotCreation}
          onClose={onClose}
          slotId={selectedSlotID!}
          timezone={timezone!}
          clinicId={clinicId}
          provider={slotProvider || currentProvider}
          oooDays={oooDays}
          operatingDaysNumerical={operatingDaysNumerical}
          view={view}
        />
      )}
    </>
  );
});

XOCal.displayName = "XOCal";

export default XOCal;
