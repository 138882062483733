import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { Link } from "components/Button";
import useFormatDate from "shared/utils/useFormatDate";
import {
  ProvidersKeyedByProviderId,
  ClinicAppointmentsKeyedByAppointmentId,
} from "components/XOCal/utils";
import { RoleOutputCoreSchema } from "shared/fetch/src/models/RoleOutputCoreSchema";
import GroupsIcon from "@mui/icons-material/Groups";
import ConflictsTableRowActions from "./ConflictsTableRowActions";
import { isGroupSlot } from "components/XOCal/utils";
import palette from "styles/mui/palette";
import ProviderBadge from "components/XOCal/ProviderBadge";
import useGetCalendarStaticData from "shared/features/xocal/useGetCalendarStaticData";
import { useSearchParams } from "react-router-dom";

interface IProps {
  row: SlotOutput;
  index: number;
  roles?: RoleOutputCoreSchema[];
  providersObject: ProvidersKeyedByProviderId;
  appointmentObjectKeyedById: ClinicAppointmentsKeyedByAppointmentId;
}

function isIndexOdd(index: number) {
  return index !== 0 && index % 2 === 1;
}

export default function ConflictsTableRow({
  row,
  index,
  roles,
  providersObject,
  appointmentObjectKeyedById,
}: IProps) {
  const formatDate = useFormatDate();

  const [searchParams] = useSearchParams();

  const { data: calendarStaticData } = useGetCalendarStaticData({
    clinicId: searchParams.get("clinicId")?.toString() as string,
  });

  const timezone = calendarStaticData?.clinicDetails?.timeZone;

  const getDateAndTime = (date: string) => {
    const dateString = formatDate(date, "MMMM d, yyyy| h:mmaaa zzz", {
      fallbackDate: "",
      tz: timezone,
    });
    return (dateString as string).split("|");
  };

  const dateAndTimeLabelArray = getDateAndTime(row.startAt!);
  const dateLabel = dateAndTimeLabelArray[0];
  const timeLabel = dateAndTimeLabelArray[1];
  const startTimeStringForRescheduleFlow = `${dateLabel} @ ${timeLabel}`;

  const labelId = `enhanced-table-checkbox-${index}`;
  const conflictedAppointment = row?.appointments![0];
  const { patientInfo } = conflictedAppointment;

  const providerName = providersObject[conflictedAppointment?.providerId]?.name;
  const serviceType =
    providersObject[conflictedAppointment?.providerId]?.serviceType;

  const appointmentTypeInfo = appointmentObjectKeyedById[row?.appointmentType!];
  const actionsOptionsAriaLabel = `Open options to resolve conflicted visit for member ${patientInfo?.name} on ${dateLabel} at ${timeLabel} with ${providerName}`;
  const phoneNumberToDisplay =
    patientInfo?.preferredPhone ||
    patientInfo?.cellPhone ||
    patientInfo?.homePhone;

  return (
    <TableRow
      tabIndex={-1}
      data-testid={"conflicts-table-row"}
      key={row.id}
      sx={
        !isIndexOdd(index)
          ? { backgroundColor: palette.appBackground.lightestGray }
          : {}
      }
    >
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        sx={{ paddingLeft: "16px" }} // replace with spacing(2)
      >
        <div>{dateLabel}</div>
        <div>{timeLabel}</div>
      </TableCell>
      <TableCell align="left" padding="normal">
        <div>
          <Link color="primary" size="small" to={`/members/${patientInfo?.id}`}>
            {patientInfo?.name}
          </Link>
        </div>
        <div>
          {patientInfo?.dob}
          {" | "}
          {patientInfo?.age}
          {" | "}
          {patientInfo?.gender}
        </div>
        <div>{phoneNumberToDisplay && phoneNumberToDisplay}</div>
      </TableCell>
      <TableCell>
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {appointmentTypeInfo?.name}
          {isGroupSlot(row) && <GroupsIcon sx={{ ml: 1.75 }} />}
        </span>
      </TableCell>
      <TableCell align="left" padding="normal">
        <ProviderBadge roles={roles} serviceType={serviceType!} />
        {providerName}
      </TableCell>
      <ConflictsTableRowActions
        patientInfo={patientInfo!}
        actionsOptionsAriaLabel={actionsOptionsAriaLabel}
        slotWithConflictedAppointment={row}
        appointmentId={conflictedAppointment.id.toString()}
        appointmentType={conflictedAppointment.appointmentType}
        appointmentTypeDisplayLabel={appointmentTypeInfo?.name}
        startTimeStringForRescheduleFlow={startTimeStringForRescheduleFlow}
        appointment={conflictedAppointment}
      />
    </TableRow>
  );
}
