import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";
import { useDispatch } from "react-redux";
import { VirtualVisitReferralComponentDetailsOutput } from "shared/api/src/models/VirtualVisitReferralComponentDetailsOutput";
import {
  fetchUpdateAppointmentApi,
  UpdateAppointmentByIdRequest,
} from "shared/fetch/src/apis/UpdateAppointmentApi";
import { showSnackbar } from "shared/state/ui/snackbar";

type UpdateAppointmentMutationFn = (
  requestParameters: UpdateAppointmentByIdRequest
) => Promise<VirtualVisitReferralComponentDetailsOutput>;

const useUpdateAppointment = (
  config?: UseMutationOptions<
    VirtualVisitReferralComponentDetailsOutput,
    Error,
    UpdateAppointmentByIdRequest
  >
): UseMutationResult<
  VirtualVisitReferralComponentDetailsOutput,
  Error,
  UpdateAppointmentByIdRequest
> => {
  const dispatch = useDispatch();

  const mutationFn: UpdateAppointmentMutationFn = (
    requestParameters: UpdateAppointmentByIdRequest
  ) => {
    return fetchUpdateAppointmentApi.updateAppointmentById(
      requestParameters
    ) as Promise<VirtualVisitReferralComponentDetailsOutput>;
  };

  const defaultConfig: UseMutationOptions<
    VirtualVisitReferralComponentDetailsOutput,
    Error,
    UpdateAppointmentByIdRequest
  > = {
    onSuccess: () => {
      dispatch(showSnackbar("Appointment has been successfully updated."));
    },
    onError: () => {
      dispatch(showSnackbar("Failed to update the appointment.", "danger"));
    },
    ...config,
  };

  return useMutation<
    VirtualVisitReferralComponentDetailsOutput,
    Error,
    UpdateAppointmentByIdRequest
  >(mutationFn, defaultConfig);
};

export default useUpdateAppointment;
