import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { useDispatch } from "react-redux";
import {
  CreateSlotRequest,
  fetchSlotsApi,
} from "shared/fetch/src/apis/SlotsApi";
import { showSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotsQueryKey } from "./useGetSlots";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import { JsonUser } from "shared/fetch/src/models/JsonUser";

type CreateSlotMutationFn = (
  requestParameters: CreateSlotRequest
) => Promise<SlotOutput>;

const useCreateSlot = (
  config?: UseMutationOptions<SlotOutput, Error, CreateSlotRequest>
): UseMutationResult<SlotOutput, Error, CreateSlotRequest> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const user = useSelector(getUser) as JsonUser;
  if (!user?.hasFullXoCalPermissions) {
    return useMutation<SlotOutput, Error, CreateSlotRequest>(
      () => {
        throw new Error("You do not have permission to create slots");
      },
      {
        onError: () => {
          dispatch(
            showSnackbar("You do not have permission to create slots", "danger")
          );
        },
      }
    );
  }

  const mutationFn: CreateSlotMutationFn = (
    requestParameters: CreateSlotRequest
  ) => {
    // appointmentTypes is [] is the component but is [undefined]
    // when logged here [undefined] causes the request to fail, so
    // reset appointmentTypes to a reasonable value if needed
    const isBadAppointmentType =
      requestParameters.createSlotCore?.appointmentTypes?.length === 1 &&
      !Boolean(requestParameters.createSlotCore?.appointmentTypes[0]);

    if (isBadAppointmentType) {
      requestParameters.createSlotCore!.appointmentTypes = [];
    }

    // now make the request
    return fetchSlotsApi.createSlot(requestParameters) as Promise<SlotOutput>;
  };

  const defaultConfig: UseMutationOptions<
    SlotOutput,
    Error,
    CreateSlotRequest
  > = {
    onSuccess: () => {
      dispatch(showSnackbar("Slot has been successfully created."));
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
      // TODO: Invalidate the slots query to refetch the data
    },
    onError: () => {
      dispatch(showSnackbar("Failed to create slot", "danger"));
    },
    ...config,
  };

  return useMutation<SlotOutput, Error, CreateSlotRequest>(
    mutationFn,
    defaultConfig
  );
};

export default useCreateSlot;
