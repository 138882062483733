import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useMutation } from "redux-query-react";
import makeStyles from "@mui/styles/makeStyles";
import { FormGroup } from "@mui/material";
import { State } from "@types";
import { DocumentObjectDetails } from "shared/fetch/src/models/DocumentObjectDetails";
import { showSnackbar } from "shared/state/ui/snackbar";
import useGetMemberRequiredConsents from "shared/features/selfSchedule/useGetMemberRequiredConsents";
import usePatchMemberAccountInfo from "shared/features/memberProfile/usePatchMemberAccountInfo";
import { scheduleAppointmentOnSlotMutation } from "shared/state/slots/queryConfigs";
import {
  setVisitReason,
  resetAllCareAccessData,
  setAttachedDocument,
  detachAttachedDocument,
} from "shared/state/ui/careAccess/actions";
import CareAccessContent from "features/careAccess/components/CareAccessContent";
import TextField from "components/TextField";
import Typography from "components/Typography";
import Button from "components/Button";
import Hidden from "components/Hidden";
import CareAccessAttachFiles from "features/careAccess/components/CareAccessAttachFiles";
import CareAccessAccommodations from "features/careAccess/components/CareAccessAccommodations";
import CareAccessConsents from "features/careAccess/components/CareAccessConsents";
import CareAccessSMSCheck from "features/careAccess/components/CareAccessSMSCheck";
import CareAccessPhone from "features/careAccess/components/CareAccessPhone";
import VisitDetailsCard from "features/careAccess/components/VisitDetailsCard";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  wrapper: {
    marginTop: "30px",
  },
  textField: {
    margin: "10px 0px",
    "& .MuiInputBase-root": {
      width: "526px",
      height: "163px",
      borderRadius: "8px",
      backgroundColor: palette.white,
      border: `1px solid ${palette.text.tertiary} !important`,
      "&:hover": {
        border: `1px solid ${palette.text.link} !important`,
        backgroundColor: palette?.appBackground?.main,
      },
      "& fieldset": {
        borderColor: `${palette.white} !important`,
        borderWidth: "1px !important",
      },
    },
    "& textarea": {
      alignSelf: "flex-start",
    },
    [breakpoints.down("md")]: {
      width: "100%",
      "& .MuiInputBase-root": {
        width: "100%",
      },
    },
  },
  checkboxesSection: {
    marginTop: "16px",
  },
  button: {
    margin: "40px 0px",
    [breakpoints.down("md")]: {
      width: "100%",
    },
  },
  attachFilesSpacer: {
    marginBottom: 20,
  },
}));

const FinalizePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    savedMemberId,
    locationState,
    finalize,
    datePicker,
    centers,
    visits,
  } = useSelector((state: State) => state.ui.careAccess);

  const { updateUserInfo } = usePatchMemberAccountInfo();
  const [, scheduleAppointment] = useMutation(
    scheduleAppointmentOnSlotMutation
  );
  const { consents = [] } = useGetMemberRequiredConsents({
    id: savedMemberId,
    appointmentType: visits.appointmentType.toString(),
    clinicId: centers.centerId,
    state: locationState.code,
  });

  const hasPendingConsents = consents.length > 0;

  const handleAttachFile = (_file: File, result: DocumentObjectDetails) => {
    const { id = "", name = "", thumbnailUrl = "", mime = "" } = result;
    dispatch(setAttachedDocument({ id, name, thumbnailUrl, mime }));
  };

  const handleDetachFile = (id: string) => {
    dispatch(detachAttachedDocument(id));
  };

  const handleUpdateAccountInfo = () => {
    const hearing = finalize.accommodationsList?.includes("audio");
    const vision = finalize.accommodationsList?.includes("visual");
    const translation = finalize.accommodationsList?.includes("translation");

    const userInfo = translation
      ? {
          hearing,
          vision,
          preferredLanguage: finalize.preferredLanguage.code,
          cellPhone: finalize.phone.phoneNumber,
          cellTexts: finalize.textReminder,
        }
      : {
          hearing,
          vision,
          cellPhone: finalize.phone.phoneNumber,
          cellTexts: finalize.textReminder,
        };

    updateUserInfo({
      id: savedMemberId,
      body: userInfo,
    });
  };

  const handleScheduleVisit = async () => {
    const documentIds: string[] = [];
    finalize.documents.forEach((doc) => documentIds.push(doc.id));

    handleUpdateAccountInfo();

    await scheduleAppointment({
      id: datePicker.slot?.id?.toString() || "",
      scheduleAppointmentCore: {
        patientId: savedMemberId,
        appointmentType: visits.appointmentType.toString(),
        reason: finalize.reason,
        accommodations: finalize.accommodationsList,
        documentIds,
        selfSchedule: true,
        memberCurrentUsState: locationState.code,
      },
    })
      ?.then((resp) => {
        const episodeId = resp.body.appointment.episode_id;
        const componentId = resp.body.id;
        navigate(
          `/members/${savedMemberId}/conversations/${episodeId}/component/${componentId}`
        );
        dispatch(
          showSnackbar("Your visit was scheduled successfully!", "success")
        );
        dispatch(resetAllCareAccessData());
      })
      .catch(() => {
        dispatch(
          showSnackbar(
            "Failed to create an appointment, please try again.",
            "danger"
          )
        );
      });
  };

  const handleReasonTextField = (e: any) => {
    dispatch(setVisitReason(e.target.value));
  };

  const hasAllRequiredFields = () => {
    const {
      accommodations,
      reason,
      phone,
      preferredLanguage,
      agreedToAllConsents,
    } = finalize;

    // Reason for visit is required
    // Phone number is required and has to be valid
    const requiredFields = [!!reason, phone.isValid];

    // If Language checkbox has been checked, a language is required
    if (accommodations.translation) {
      requiredFields.push(!!preferredLanguage?.code);
    }

    // If there are any consents pending they all have to be agreed to
    if (hasPendingConsents) {
      requiredFields.push(agreedToAllConsents);
    }

    return !requiredFields.includes(false);
  };

  const placeholder =
    "What are your symptoms, when did you first notice them, are they better or worse now, have you tried to alleviate them? The more information, the better.";

  return (
    <CareAccessContent>
      <div className={classes.wrapper}>
        <Hidden lgUp>
          <VisitDetailsCard />
        </Hidden>

        <Typography
          id="textfield-label"
          appearance="body"
          color="textSecondary"
        >
          Describe your concerns in as much details as possible
        </Typography>
        <TextField
          multiline
          variant="outlined"
          aria-labelledby="textfield-label"
          placeholder={placeholder}
          value={finalize.reason}
          className={classes.textField}
          onChange={handleReasonTextField}
        />

        <div className={classes.attachFilesSpacer}>
          <CareAccessAttachFiles
            handleDetachFile={handleDetachFile}
            handleAttachFile={handleAttachFile}
            documents={finalize.documents}
          />
        </div>

        <CareAccessPhone />

        <div className={classes.checkboxesSection}>
          <FormGroup>
            <CareAccessSMSCheck />
            <CareAccessAccommodations />
            {hasPendingConsents && <CareAccessConsents consents={consents} />}
          </FormGroup>
        </div>

        <Button
          disabled={!hasAllRequiredFields()}
          color="primary"
          className={classes.button}
          onClick={handleScheduleVisit}
        >
          Schedule visit
        </Button>
      </div>
    </CareAccessContent>
  );
};

export default FinalizePage;
