import React from "react";
import classes from "./index.module.css";
import { Button, Link, Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ValuesType } from "./utils";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import { getPronouns } from "components/Sogi/utils";
import { prepareAge } from "utils/calculateAge";
import useFormatDate from "shared/utils/useFormatDate";
import CancelIcon from "@mui/icons-material/Cancel";
import { AppointmentPatientInfoObject } from "shared/fetch/src/models/AppointmentPatientInfoObject";

const MemberInformation = ({
  values,
  editMember,
  cancelVisit,
  appointmentType,
  isRenderedInCancelModal,
  patientInfoFromConflictsQueue,
  appointmentTypeDisplayLabel,
}: {
  values?: ValuesType;
  editMember: boolean;
  cancelVisit?: boolean;
  appointmentType: string | undefined;
  isRenderedInCancelModal?: boolean;
  patientInfoFromConflictsQueue?: AppointmentPatientInfoObject;
  appointmentTypeDisplayLabel?: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const formatDate = useFormatDate();

  // get member and acct info from props and params
  const member = searchParams.get("member");
  const scheduledMemberId =
    values?.appointments && values?.appointments[0]?.patientId;
  const memberId = member || scheduledMemberId?.toString();
  const memberInfo = useGetAccountInfo({ id: memberId ?? "" });
  const accountInfo = memberInfo.accountInfo;

  // demographic info
  const memberPronouns = accountInfo.pronouns
    ? getPronouns(accountInfo.pronouns, true)
    : undefined;
  const memberName = accountInfo.legalAndPreferredName;
  const age = accountInfo.age ? prepareAge(accountInfo.age) : "";
  const sexAtBirth = accountInfo.sexAtBirth;
  const cellPhone = patientInfoFromConflictsQueue?.cellPhone
    ? patientInfoFromConflictsQueue.cellPhone
    : accountInfo.cellPhone;

  const memberBirthdate = formatDate(accountInfo.dob, "MM/dd/yyyy", {
    fallbackDate: "",
    showAsTodayIfSameDay: false,
    isDoB: true,
  });

  // caption is different in different use cases of modal
  const defaultCaption = cancelVisit
    ? appointmentType
    : `${
        memberPronouns ? `${memberPronouns} |` : ""
      } ${memberBirthdate} | ${age} | ${sexAtBirth}`;

  const displayedPronouns = patientInfoFromConflictsQueue?.pronouns
    ? `${getPronouns(patientInfoFromConflictsQueue.pronouns, true)} | `
    : "";
  // member object is not available from conflicts queue, so we have a different patient info object
  const conflictsQueueCaption = `${displayedPronouns}${patientInfoFromConflictsQueue?.dob} | ${patientInfoFromConflictsQueue?.age} | ${patientInfoFromConflictsQueue?.gender}`;

  // determine correct caption for display
  const caption = isRenderedInCancelModal
    ? conflictsQueueCaption
    : defaultCaption;

  // same thing for the user name
  const getMemberName = () => {
    if (isRenderedInCancelModal) {
      return patientInfoFromConflictsQueue?.name;
    } else {
      return memberName;
    }
  };
  const memberNameLabel = getMemberName();

  const canMemberBeRemovedFromVisit = !editMember && !isRenderedInCancelModal;
  const isMemberNameAnchorElement = !cancelVisit;

  const handleRemoveMemberClick = () => {
    searchParams.delete("member");
    setSearchParams(searchParams);
  };

  return (
    <div className={classes.memberInformation} data-testid="member-information">
      <div className={classes.memberInfoTitle}>
        {" "}
        {isMemberNameAnchorElement ? (
          <Link
            href={
              memberId
                ? `/members/${encodeURIComponent(memberId)}/demographics`
                : ""
            }
          >
            {memberNameLabel}
          </Link>
        ) : (
          <p>{memberNameLabel}</p>
        )}
        {canMemberBeRemovedFromVisit && (
          <Button
            color="inherit"
            onClick={handleRemoveMemberClick}
            aria-label={`remove member ${memberNameLabel}`}
          >
            <CancelIcon />
          </Button>
        )}
      </div>
      <p>{caption}</p>
      <p>{cellPhone}</p>
      {appointmentTypeDisplayLabel && (
        <Box paddingTop={1} color="black">
          {appointmentTypeDisplayLabel}
        </Box>
      )}
    </div>
  );
};

export default MemberInformation;
