export default (serviceLine: string) => {
  const map = {
    medical: "Primary care",
    primary_care_center: "Primary care",
    medical_xo: "Primary care",
    physical_medicine: "Physical therapy",
    physical_medicine_center: "Physical therapy",
    mental_health: "Mental health",
    mental_health_center: "Mental health",
    health_coaching: "Health coaching",
    health_coaching_center: "Health coaching",
    nurse_triage: "Nursing",
    travel_medicine: "Primary care",
    care_navigation_xo: "Care navigation",
    nurse_everything: "Nursing",
    care_navigation: "Care navigation",
    first_aid: "First aid",
    flu_covid_only_cn: "Primary care",
    flu_covid_only_med: "Primary care",
    acupuncture: "Acupuncture care",
    chiropractic: "Chiropractic care",
    massage: "Massage care",
    optometry: "Optometry care",
    VSP: "Vision care", // this was in COS
    vsp: "Vision care",
    utd_preventive_feature: "Preventive",
    eap: "EAP",
    host: "Host",
    lab: "Lab",
    fitness: "Fitness",
    dental: "Dental",
    medical_assistant: "Medical assistant",
    sports_medicine: "Sports medicine",
    dermatology: "Dermatology",
    psychiatry: "Psychiatry",
    occupational_health: "Occupational Medicine",
  };

  return map[serviceLine];
};
