import React from "react";
import Dialog from "components/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import classes from "./SlotActionDrawer/index.module.css";
import { ButtonBase, Typography } from "@mui/material";
import Button from "components/Button";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import useSetOOO from "shared/features/xocal/useSetOOO";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import useDeleteSlotDateRange from "shared/features/xocal/useDeleteSlotDateRange";
import { getFetchOOOQueryKey } from "shared/features/xocal/useGetOOO";
import { getFetchSlotsQueryKey } from "shared/features/xocal/useGetSlots";
import { useQueryClient } from "react-query";

interface IProps {
  confirmOOOorClearModalOpen: boolean;
  setConfirmOOOorClearModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  provider: string | undefined;
  providerId: string | undefined;
  selectedDates: Date[];
  setOOOorClearModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isClear: boolean;
}

const OOOorClearConfirmModal = ({
  confirmOOOorClearModalOpen,
  setConfirmOOOorClearModalOpen,
  provider,
  selectedDates,
  setOOOorClearModalOpen,
  providerId,
  isClear,
}: IProps) => {
  const dispatch = useDispatch();
  const selectedDatesSorted = selectedDates.sort(
    (a, b) => a.getTime() - b.getTime()
  );
  let dateString = "";

  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const startDate = selectedDatesSorted[0]
    ? format(selectedDatesSorted[0], "Y/MM/dd")
    : "";
  const endDate = selectedDatesSorted[selectedDatesSorted.length - 1]
    ? format(selectedDatesSorted[selectedDatesSorted.length - 1], "Y/MM/dd")
    : "";

  const clinicId = searchParams.get("clinicId");

  selectedDatesSorted.forEach((date, i) => {
    if (
      i === selectedDatesSorted.length - 1 &&
      selectedDatesSorted.length > 1
    ) {
      return (dateString += "to " + format(date, "E MMM d"));
    } else {
      return (dateString += format(date, "E MMM d") + " ");
    }
  });

  const { mutateAsync: setOOO } = useSetOOO({
    onSuccess: () => {
      dispatch(
        showSnackbar(
          `All slots for ${provider} on ${dateString} have been deleted and ${provider} has been set Out of Office. Any scheduled visits are now added to the Conflicts.`
        )
      );
      providerId &&
        queryClient.invalidateQueries(
          getFetchOOOQueryKey({ providerIds: [providerId] })
        );
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    },
  });

  const { mutateAsync: deleteSlotDateRange } = useDeleteSlotDateRange({
    onSuccess: () => {
      dispatch(
        showSnackbar(
          `All slots for ${provider} on ${dateString} have been deleted. Any scheduled visits are now added to the Conflicts.`
        )
      );
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    },
  });

  const handleConfirmOOOorClear = () => {
    if (!isClear) {
      setOOO({
        oOONotificationRequestBody: {
          providerId: providerId!,
          clinicId: clinicId!,
          startDate,
          endDate,
        },
      });
    } else {
      deleteSlotDateRange({
        deleteSlotSeriesRequestBody: {
          providerId: providerId!,
          clinicId: clinicId!,
          startDate,
          endDate,
        },
      });
    }
    setConfirmOOOorClearModalOpen(false);
  };

  return (
    <Dialog
      open={confirmOOOorClearModalOpen}
      ariaLabelledBy={"dialog-title"}
      onClose={() => setConfirmOOOorClearModalOpen(false)}
    >
      {" "}
      <div className={classes.oooMemberModal} data-testid="ooo-modal">
        <div className={classes.oooModalTitleContainer}>
          <ButtonBase
            className={classes.oooModalCancel}
            role="button"
            aria-label={"Close confirm modal"}
            onClick={() => {
              setConfirmOOOorClearModalOpen(false);
              setOOOorClearModalOpen(true);
            }}
          >
            <CloseIcon className={classes.closeIcon} />
          </ButtonBase>
          <DialogTitle className={classes.cancelModalTitle} id="dialog-title">
            {" "}
            {isClear ? "Clear Provider's Schedule" : "Set Out of Office"}
          </DialogTitle>{" "}
        </div>
        <DialogContent>
          <DialogContentText variant={"body2"}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography>
                All slots for{" "}
                <strong style={{ color: "#2B3949" }}>{provider} </strong> on{" "}
                <strong style={{ color: "#2B3949" }}>{dateString}</strong> will
                be {isClear ? " deleted" : " marked as OOO"}. If there are
                scheduled visits, they will go to the Conflicts Queue.
              </Typography>
            </div>
          </DialogContentText>
          <DialogActions className={classes.oooModalActions}>
            <Button
              color={"primary"}
              onClick={handleConfirmOOOorClear}
              aria-label={
                isClear
                  ? "Confirm days to clear schedule"
                  : "Confirm set out of office days"
              }
              className={classes.cancelMemberButton}
            >
              Submit
            </Button>
            <Button
              color={"link-primary"}
              onClick={() => {
                setConfirmOOOorClearModalOpen(false);
                setOOOorClearModalOpen(true);
              }}
              aria-label={
                isClear
                  ? "Cancel, do not clear schedule"
                  : "Cancel, do not set OOO"
              }
            >
              {" "}
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default OOOorClearConfirmModal;
