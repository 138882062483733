import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";
import { useDispatch } from "react-redux";
import {
  DeleteSlotDateRangeRequest,
  fetchSlotsApi,
} from "shared/fetch/src/apis/SlotsApi";
import { DeleteDateRangeSlots } from "shared/fetch/src/models/DeleteDateRangeSlots";
import { showSnackbar } from "shared/state/ui/snackbar";

type DeleteSlotDateRangeMutationFn = (
  requestParameters: DeleteSlotDateRangeRequest
) => Promise<DeleteDateRangeSlots>;

const useDeleteSlotDateRange = (
  config?: UseMutationOptions<
    DeleteDateRangeSlots,
    Error,
    DeleteSlotDateRangeRequest
  >
): UseMutationResult<
  DeleteDateRangeSlots,
  Error,
  DeleteSlotDateRangeRequest
> => {
  const dispatch = useDispatch();

  const mutationFn: DeleteSlotDateRangeMutationFn = (
    requestParameters: DeleteSlotDateRangeRequest
  ) => {
    return fetchSlotsApi.deleteSlotDateRange(
      requestParameters
    ) as Promise<DeleteDateRangeSlots>;
  };

  const defaultConfig: UseMutationOptions<
    DeleteDateRangeSlots,
    Error,
    DeleteSlotDateRangeRequest
  > = {
    onSuccess: () => {
      dispatch(showSnackbar("Slot range has been successfully deleted."));
    },
    onError: () => {
      dispatch(showSnackbar("Failed to delete the slot range.", "danger"));
    },
    ...config,
  };

  return useMutation<DeleteDateRangeSlots, Error, DeleteSlotDateRangeRequest>(
    mutationFn,
    defaultConfig
  );
};

export default useDeleteSlotDateRange;
