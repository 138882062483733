import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import {
  CentersState,
  ICurrentLocation,
  ISelectedProvider,
  IDocument,
  IAccommodations,
  IPhone,
  MessageRecipientState,
  SET_APPOINTMENT_TYPE,
  SET_VISIT_FILTER,
  SET_SELECTED_CENTER,
  SET_CURRENT_STEP,
  SET_SERVICE,
  SET_USER_ID,
  SET_CARE_TYPE,
  SET_DIRECT_MESSAGE_STEP,
  SET_LOCATION_STATE,
  SET_SELECTED_PROVIDER,
  SET_SELECTED_SLOT_PROVIDER,
  SET_SELECTED_SLOT,
  SET_ATTACHED_DOCUMENT,
  DETACH_ATTACHED_DOCUMENT,
  SET_PHONE_NUMBER,
  SET_TEXT_REMINDER,
  SET_ACCOMMODATIONS,
  SET_PREFERRED_LANGUAGE,
  SET_ACCOMMODATION_CHECK,
  SET_REASON,
  RESET_ALL_DATA,
  SET_CONSENTS_AGREEMENT_STATUS,
  SET_MESSAGE_BODY,
  SET_MESSAGE_DOCUMENTS,
  SET_MESSAGE_SUBJECT,
  DELETE_MESSAGE_DOCUMENT,
  SET_MESSAGE_RECIPIENT,
  SET_HAS_SKIPPED_DECISIONS_PAGE,
  RESET_MESSAGE_DATA,
  RESET_IS_PAYMENTS_REDIRECT,
  RESET_PREVIOUS_URL_IF_REDIRECT,
  RESET_REASON_FOR_VISIT,
} from ".";

export const setUserId = (memberId: string) => {
  return { type: SET_USER_ID, payload: memberId };
};

export const setVisitFilter = (filter: string) => {
  return { type: SET_VISIT_FILTER, payload: filter };
};

export const setAppointmentType = (
  appointmentType: Number,
  appointmentLabel: string
) => {
  return {
    type: SET_APPOINTMENT_TYPE,
    payload: { appointmentType, appointmentLabel },
  };
};

export const setSelectedCenter = (selectedCenter: CentersState) => {
  const { centerId, centerName } = selectedCenter;
  return { type: SET_SELECTED_CENTER, payload: { centerId, centerName } };
};

interface ICurrentStep {
  step?: string;
  isBackButton?: boolean;
}
export const setCurrentStep = ({ step, isBackButton }: ICurrentStep) => {
  return { type: SET_CURRENT_STEP, payload: { step, isBackButton } };
};

export const setService = (serviceLineId: string) => {
  return { type: SET_SERVICE, payload: { serviceLineId } };
};

export const setCareType = (careType: string) => {
  return { type: SET_CARE_TYPE, payload: { careType } };
};

export const setDirectMessageStep = ({
  isPaymentsRedirect,
}: {
  isPaymentsRedirect: boolean;
}) => {
  return { type: SET_DIRECT_MESSAGE_STEP, payload: { isPaymentsRedirect } };
};

export const setHasSkippedDecisionsPage = (value: boolean) => {
  return { type: SET_HAS_SKIPPED_DECISIONS_PAGE, payload: value };
};

export const setCurrentLocation = (locationState: ICurrentLocation) => {
  return { type: SET_LOCATION_STATE, payload: { locationState } };
};

export const setSelectedProvider = (provider: ISelectedProvider) => {
  return { type: SET_SELECTED_PROVIDER, payload: provider };
};

export const setSelectedSlotProvider = (slotProvider: any) => {
  return { type: SET_SELECTED_SLOT_PROVIDER, payload: slotProvider };
};

export const setSelectedSlot = (slot: SlotOutput) => {
  return { type: SET_SELECTED_SLOT, payload: slot };
};

export const setVisitReason = (reason: string) => {
  return { type: SET_REASON, payload: reason };
};

export const setAttachedDocument = (document: IDocument) => {
  return { type: SET_ATTACHED_DOCUMENT, payload: document };
};

export const detachAttachedDocument = (id: string) => {
  return { type: DETACH_ATTACHED_DOCUMENT, payload: id };
};

export const setPhoneNumber = (phoneDetails: IPhone) => {
  return { type: SET_PHONE_NUMBER, payload: phoneDetails };
};

export const setTextReminder = (textReminder: boolean) => {
  return { type: SET_TEXT_REMINDER, payload: textReminder };
};

export const setINeedAccommodations = (value: boolean) => {
  return { type: SET_ACCOMMODATION_CHECK, payload: value };
};

export const setAccommodations = (accommodations: IAccommodations) => {
  return { type: SET_ACCOMMODATIONS, payload: accommodations };
};

export const setPreferredLanguage = (preferredLanguage: string) => {
  return { type: SET_PREFERRED_LANGUAGE, payload: preferredLanguage };
};

export const resetAllCareAccessData = () => {
  return { type: RESET_ALL_DATA };
};

export const setConsentsAgreementStatus = (value: boolean) => {
  return { type: SET_CONSENTS_AGREEMENT_STATUS, payload: value };
};

export const setMessageBody = (value: string) => {
  return { type: SET_MESSAGE_BODY, payload: value };
};

export const setMessageSubject = (value: string) => {
  return { type: SET_MESSAGE_SUBJECT, payload: value };
};

export const setMessageDocuments = (value: IDocument[]) => {
  return { type: SET_MESSAGE_DOCUMENTS, payload: value };
};

export const deleteMessageDocument = (value: string) => {
  return { type: DELETE_MESSAGE_DOCUMENT, payload: value };
};

export const setMessageRecipient = (payload: MessageRecipientState) => {
  return { type: SET_MESSAGE_RECIPIENT, payload };
};

export const resetMessageData = () => {
  return { type: RESET_MESSAGE_DATA };
};

export const resetIsPaymentsRedirect = () => {
  return { type: RESET_IS_PAYMENTS_REDIRECT };
};

export const resetPreviousUrlIfRedirect = () => {
  return { type: RESET_PREVIOUS_URL_IF_REDIRECT };
};

export const resetReasonForVisit = () => {
  return { type: RESET_REASON_FOR_VISIT };
};
