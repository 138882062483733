import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { useDispatch } from "react-redux";
import {
  fetchSlotSeriesApi,
  UpdateSlotSeriesRequest,
} from "shared/fetch/src/apis/SlotSeriesApi";
import { SlotSeriesOutput } from "shared/fetch/src/models/SlotSeriesOutput";
import { showSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotQueryKey } from "./useGetSlot";
import { getFetchSlotsQueryKey } from "./useGetSlots";
import { SlotSeriesUpdateResponseUnion } from "shared/fetch/src/models/SlotSeriesUpdateResponseUnion";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import { JsonUser } from "shared/fetch/src/models/JsonUser";

const useUpdateSlotSeries = (
  config?: UseMutationOptions<
    SlotSeriesUpdateResponseUnion,
    Error,
    UpdateSlotSeriesRequest
  >
): UseMutationResult<
  SlotSeriesUpdateResponseUnion,
  Error,
  UpdateSlotSeriesRequest
> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const user = useSelector(getUser) as JsonUser;

  if (!user?.hasFullXoCalPermissions) {
    return useMutation<
      SlotSeriesUpdateResponseUnion,
      Error,
      UpdateSlotSeriesRequest
    >(
      () => {
        throw new Error("You do not have permission to update slot series");
      },
      {
        onError: () => {
          dispatch(
            showSnackbar(
              "You do not have permission to update slot series",
              "danger"
            )
          );
        },
      }
    );
  }

  const mutationFn = (requestParameters: UpdateSlotSeriesRequest) => {
    return fetchSlotSeriesApi.updateSlotSeries(requestParameters);
  };

  const defaultConfig: UseMutationOptions<
    SlotSeriesUpdateResponseUnion,
    Error,
    UpdateSlotSeriesRequest
  > = {
    onSuccess: (data) => {
      if (data.type !== "dry_run") {
        dispatch(showSnackbar("Success! The slot settings have been updated."));
      }
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
      const slotSeries = data as SlotSeriesOutput;
      if (slotSeries.id) {
        queryClient.invalidateQueries(
          getFetchSlotQueryKey({ id: slotSeries.id.toString() })
        );
      }
    },
    onError: () => {
      dispatch(showSnackbar("Failed to update the slot.", "danger"));
    },
    ...config,
  };

  return useMutation<
    SlotSeriesUpdateResponseUnion,
    Error,
    UpdateSlotSeriesRequest
  >(mutationFn, defaultConfig);
};

export default useUpdateSlotSeries;
