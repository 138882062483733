import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { useDispatch } from "react-redux";
import { CreateSlotCore } from "shared/api/src/models/CreateSlotCore";
import { fetchSlotsApi, UpdateSlotRequest } from "shared/fetch/src/apis";
import { showSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotQueryKey } from "./useGetSlot";
import { getFetchSlotsQueryKey } from "./useGetSlots";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import { JsonUser } from "shared/fetch/src/models/JsonUser";

type UpdateSlotMutationFn = (
  requestParameters: UpdateSlotRequest
) => Promise<CreateSlotCore>;

const useUpdateSlot = (
  config?: UseMutationOptions<CreateSlotCore, Error, UpdateSlotRequest>
): UseMutationResult<CreateSlotCore, Error, UpdateSlotRequest> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const user = useSelector(getUser) as JsonUser;

  // Permission check
  if (!user?.hasFullXoCalPermissions) {
    return useMutation<CreateSlotCore, Error, UpdateSlotRequest>(
      () => {
        throw new Error("You do not have permission to update slots");
      },
      {
        onError: () => {
          dispatch(
            showSnackbar("You do not have permission to update slots", "danger")
          );
        },
      }
    );
  }

  const mutationFn: UpdateSlotMutationFn = (
    requestParameters: UpdateSlotRequest
  ) => {
    return fetchSlotsApi.updateSlot(
      requestParameters
    ) as Promise<CreateSlotCore>;
  };

  const defaultConfig: UseMutationOptions<
    CreateSlotCore,
    Error,
    UpdateSlotRequest
  > = {
    onSuccess: (data) => {
      dispatch(showSnackbar("Slot has been successfully updated."));
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
      queryClient.invalidateQueries(getFetchSlotQueryKey({ id: data.id }));
    },
    onError: () => {
      dispatch(showSnackbar("Failed to update the slot.", "danger"));
    },
    ...config,
  };

  return useMutation<CreateSlotCore, Error, UpdateSlotRequest>(
    mutationFn,
    defaultConfig
  );
};

export default useUpdateSlot;
